import { ChangeEvent, MutableRefObject, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Field, FormikProps, FormikProvider, useFormik, withFormik } from 'formik';
import { Dispatch } from 'redux';
import { InfoRounded, WarningRounded } from '@material-ui/icons';
import { Box, Grid, Typography } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, difference, isNumber, keys, lowerFirst, orderBy, uniq } from 'lodash';
import { AnySchema } from 'yup';

// Components
import InputBlock from 'components/global/inputBlock/inputBlock';
import InputBlockWithErrorTouchCheck from '../../../../components/global/inputBlock/inputBlockWithErrorTouchCheck';
import Button from 'components/Button/Button';
import { Stepper } from 'components/Stepper';
import { IStepProps } from 'components/Stepper/Step';
import ButtonRow from './buttonRow';
import { LocationDetailsRepeater } from 'components/LocationDetailsRepeater';
import { CompletedActivityCard } from 'layouts/pages/activity/add-activity/components';

// Services
import { FormService } from 'services/FormService';

// Store + Core
import {
  amaCreditTermIdSelector,
  ipceCreditTermIdSelector,
  outcomeMeasureChildTermsSelector,
  pharmacyCreditTermIdSelector,
  taxonomyRootTermsSelector,
} from 'store/taxonomy/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { getAllBoards, getBoardById } from 'store/board/actions';
import { openRail } from 'store/rail/actions';
import { activitySelector, activityTypesSelector, liveCourseSelector, rssSelector } from 'store/activity/selectors';
import { organizationKindSelector, rollupOrganizationEnumSelector } from 'store/user/selectors';
import {
  Activity,
  ActivityFormStateValues,
  ActivityType,
  AddActivityFormValues,
  BoardMocDetails,
  CommercialSupportSource,
  ContentTag,
  IBoardRemsDetails,
  IBoardSlim,
  IDatesAndLocation,
  IDictionary,
  IFormSection,
  IInKindSupportSourceOption,
  IMonetarySupportSourceOption,
  ITaxonomyTerm,
  IUserOrganization,
  RollupOrganizationEnums,
  HasStateContentTags,
  HasPharmRecertTemplateOptions,
  HasPharmContentTagTemplateOptions,
} from 'core/models';
import { boardSelector, configuredBoardsDictionarySelector, configuredBoardsSelector } from 'store/board/selectors';
import { clearCurrentActivities } from 'store/activity/actions';
import { FormikCheckboxField } from '../../../../components/ContinuousImprovement/PureFormElements';

// Constants
import { defaultAddFormProps, termSetIds } from 'layouts/pages/activity/constants';
import { BoardTypes, IBCTBoard } from 'layouts/pages/bct/types';

// Misc
import { blankSchema } from './validation/validationSchemas';
import { useActivityTemplate, useFilterHook } from './hooks';
import { getAllowedBoardSelections } from '../utils';

// Styles
import styles from './index.module.scss';
import { configuredContentTagsHierarchySelector } from 'store/contentTags/selectors';
import { getAllContentTags } from 'store/contentTags/actions';

const recreateMonetarySupportSourceOption = ({
  source,
  amountGiven,
}: CommercialSupportSource): IMonetarySupportSourceOption => ({
  amountGiven,
  id: source,
  source: source,
});
const recreateInKindSupportSourceOption = ({ source }: CommercialSupportSource): IInKindSupportSourceOption => source;

function resetObjectAttributes<T extends Record<string, any>>(
  obj: T,
  attributeValuePairs: Array<[keyof T, any]>,
): void {
  Object.entries(obj).forEach(([key]) => {
    attributeValuePairs.forEach(([attribute, value]) => {
      obj[key][attribute] = value;
    });
  });
}

interface IActivityProps {
  activity: Activity[];
  activeStep: IStepProps;

  decrementStep(): void;

  copiedActParams: any;
  formSteps: IStepProps[];

  goToStep(label: string): void;

  initialValues?: AddActivityFormValues;
  isDraft: boolean;
  isFirstStep: boolean;
  isLastStep: boolean;
  isSubmitStep: boolean;

  onSubmit(values: AddActivityFormValues): Promise<void>;

  updateCollaborationSteps(isMoc, isRems, isContentTag): void;

  validationSchemas?: AnySchema[];
  processedInitValues?: MutableRefObject<boolean>;
  dictionaries: IDictionary<IDictionary<ITaxonomyTerm>>;
}

const AddActivityFormik = (props: IActivityProps & FormikProps<ActivityFormStateValues>): ReactElement => {
  const {
    activity,
    activeStep,
    formSteps,
    copiedActParams,
    decrementStep,
    goToStep: skipToStep,
    isFirstStep,
    isLastStep,
    isSubmitStep,
    onSubmit,
    updateCollaborationSteps,
    validationSchemas,
  } = props;

  const formTitle = 'Add an Activity';
  const formError = 'You have incomplete information on this page. Please complete to move on to the next step.';
  const formAlert = 'Updates can only be made to editable fields.';
  const pharmacyQuestionFields = [
    'targetAudience',
    'pharmacyTypeId',
    'isPharmacyCertificateProgram',
    'pharmacyTopicId',
    'pharmacyLiveDates',
  ];

  const cmePassportQuestionFields = ['detailsUrl', 'isRestrictedAudience', 'participationFeeTypeId'];
  const { isAuthenticated } = useAuth0();

  const [generatedFields, setGeneratedFields] = useState([] as Array<JSX.Element>);
  const [showGlobalError, setShowGlobalError] = useState<boolean>(false);
  const [isLoadingBoards, setIsLoadingBoards] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [requestedBoardIds, setRequestedBoardIds] = useState<string[]>([]);

  const dispatch: Dispatch<any> = useDispatch();

  // Setup Formik.
  const formik = useFormik({
    initialValues: copiedActParams
      ? { ...props.initialValues }
      : {
          ...props.initialValues,
          ...defaultAddFormProps,
        },
    enableReinitialize: !!copiedActParams && !!copiedActParams.parms,
    onSubmit: async (values: AddActivityFormValues, { setSubmitting }): Promise<void> => {
      // Indicate the form is submitting
      await setSubmitting(true);

      // List of properties to exclude
      const fieldsToExclude = ['city', 'country', 'endDate', 'postalCode', 'startDate', 'stateOrProvince'];

      // Create a new object excluding specified fields
      const updatedValues = Object.fromEntries(
        Object.entries(values).filter(([key]) => !fieldsToExclude.includes(key)),
      ) as AddActivityFormValues;

      // Submit the updated values
      await onSubmit(updatedValues);

      // Scroll to the top after successful submission
      await window.scroll({
        behavior: 'smooth',
        left: 0,
        top: 0,
      });

      // Reset submitting state
      await setSubmitting(false);
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: (): AnySchema => {
      if (activeStep && validationSchemas) {
        const schemaMapping = {
          Basics: validationSchemas[0],
          'Information for Learners': validationSchemas[1],
          'Accreditation Details': validationSchemas[2],
          'State Content Tagging': validationSchemas[3],
          MOC: validationSchemas[4],
          REMS: validationSchemas[5],
        };

        return schemaMapping[activeStep.label] || blankSchema;
      } else {
        if (activeStep && validationSchemas) {
          const schemaMapping = {
            Basics: validationSchemas[0],
            'Information for Learners': validationSchemas[1],
            'Accreditation Details': validationSchemas[2],
            'State Content Tagging': validationSchemas[3],
            MOC: validationSchemas[4],
            REMS: validationSchemas[5],
          };

          return copiedActParams ?? schemaMapping;
        }
        return blankSchema;
      }
    },
  });

  const {
    dirty,
    errors,
    handleSubmit,
    initialValues,
    isSubmitting,
    isValid,
    resetForm,
    setErrors,
    setFieldValue,
    touched,
    values,
  } = formik;

  const goToStep = (label: string): void => {
    skipToStep(label);
    setErrors({});
  };

  // Redux State.
  const activityTemplate = useActivityTemplate();
  const activityTypes = useSelector(activityTypesSelector);

  // const contentTagsChildren = useSelector(contentTagsSelector);
  const contentTagsDetails: ContentTag[] = useSelector(configuredContentTagsHierarchySelector);

  const amaCreditId = useSelector(amaCreditTermIdSelector)?.id;
  const ipceCreditId = useSelector(ipceCreditTermIdSelector)?.id;
  const baseTerms = useSelector(taxonomyRootTermsSelector);
  const liveCourseId = useSelector(liveCourseSelector)?.id;
  const pharmacyCreditsTermId = useSelector(pharmacyCreditTermIdSelector)?.id;
  const rssId = useSelector(rssSelector)?.id;
  const boards = useSelector(boardSelector);
  const terms = useSelector(taxonomyRootTermsSelector);
  const nextSequenceNumber: number = useSelector(activitySelector)?.nextSequenceNumber;
  const configuredBoards: IBCTBoard[] = useSelector(configuredBoardsSelector);
  const configurationDictionary: IDictionary<IBCTBoard> = useSelector(configuredBoardsDictionarySelector);
  // Get the L2 terms for outcome measured and make a map of their ids for the useEffect of nested checkboxes.
  const outcomeMeasuredTerms = useSelector(outcomeMeasureChildTermsSelector).map(({ id }: ITaxonomyTerm) => id);
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const isJa = rollupOrganizationEnum === RollupOrganizationEnums.JA;
  const isNars = rollupOrganizationEnum === RollupOrganizationEnums.NARS;
  const currentUserOrganization: IUserOrganization = useSelector(organizationKindSelector);
  const isPharmacyRecert = values.hasPharmacyRecertifications?.endsWith('1');

  // Separate boards by board type
  const configuredMocBoards: IBCTBoard[] = useFilterHook(BoardTypes.CERTIFYING_BOARD, configuredBoards);
  const configuredRemsBoards: IBCTBoard[] = useFilterHook(BoardTypes.REMS, configuredBoards);

  // 4386 - Depending on activity type, some boards need to be filtered
  const activityTypeId: string = values?.typeId;
  const allowedBoardSelections: IBoardSlim[] = getAllowedBoardSelections(boards, activityTypeId);
  const allMocBoards: IBoardSlim[] = allowedBoardSelections?.filter(
    ({ type }: IBoardSlim): boolean => type === BoardTypes.CERTIFYING_BOARD,
  );
  const allRemsBoards: IBoardSlim[] = allowedBoardSelections?.filter(
    ({ type }: IBoardSlim): boolean => type === BoardTypes.REMS,
  );
  const allContentTags = useMemo(
    () =>
      Object.values(contentTagsDetails)
        .flat()
        .map((tag) => tag),
    [contentTagsDetails],
  );

  const isPharmacyCreditsChecked: boolean = (values?.credits?.[pharmacyCreditsTermId] as number) > 0;
  const isPharmacyAbbreviationL: boolean =
    activityTypes.find((type: ActivityType): boolean => type.id === values.typeId)?.pharmacyAbbreviation === 'L';
  const doesAMACreditHaveValue = !!values?.credits?.[amaCreditId];
  const doesIPCECreditHaveValue = !!values?.credits?.[ipceCreditId];

  const runOnceDates = useRef(false);
  const runOnceMOCS = useRef(false);
  const runOnceCme = useRef(false);
  const setMOCWhenCopied = useRef(false);
  const initialRender = useRef(true);
  const runOnceInforForLearners = useRef(false);

  useEffect(() => {
    if (!allContentTags?.length) {
      dispatch(getAllContentTags());
    }
  }, [allContentTags, dispatch, isAuthenticated]);

  // For Basics set defaults on step load
  useEffect(() => {
    if (copiedActParams && activeStep && activeStep.label === 'Basics') {
      // empty value for board to avoid cme credit error and next seq number error
      setFieldValue('pharmacySequenceNumber', undefined);
      setFieldValue('isMoc', 'isMoc-false');
      if (values.tempDatesAndLocation?.length) {
        // @ts-ignore // objects deep clone isnt registering in pretty
        setFieldValue('startDate', values.tempDatesAndLocation[0]?.startDate);
        // @ts-ignore
        setFieldValue('endDate', values.tempDatesAndLocation[0]?.endDate);
      }
      setFieldValue('locationType', values.locationType);
    }
  }, [activeStep, values.locationType, values.typeId, values.tempDatesAndLocation]);
  // For Information for Learners set defaults on step load
  useEffect(() => {
    if (!runOnceDates.current && copiedActParams && activeStep && activeStep.label === 'Basics') {
      // empty values for the start and end date
      setFieldValue('startDate', null);
      setFieldValue('endDate', null);
      runOnceDates.current = true;
    }
  }, [activeStep, values.tempDatesAndLocation]);

  // For  Accreditation Details clean up
  useEffect(() => {
    const isAccreditationDetails = copiedActParams && activeStep && activeStep.label === 'Accreditation Details';
    if (isAccreditationDetails && values.hasCommercialSupport.includes('false')) {
      setFieldValue('commercialSupportSourceKind', []);
      setFieldValue('commercialSupportSources', []);
      setFieldValue('commercialInKindSupportSources', []);
      setFieldValue('commercialMonetarySupportSources', []);
      document.querySelector('[data-group-id="MonetarySupportSourceGroup"]')?.classList.add('hidden');
      document.querySelector('[data-group-id="InKindSupportSourceGroup"]')?.classList.add('hidden');
    } else {
      if (isNars) {
        setFieldValue('isJointlyProvided', 'direct');
      }
      document.querySelector('[data-group-id="MonetarySupportSourceGroup"]')?.classList.remove('hidden');
      document.querySelector('[data-group-id="InKindSupportSourceGroup"]')?.classList.remove('hidden');
    }
  }, [activeStep, isNars, values.hasCommercialSupport, values.isIndividualActivity]);

  // For Information for Learners set defaults on step load ensure that the details load
  useEffect(() => {
    const isInformationForLearners = copiedActParams && activeStep && activeStep.label === 'Information for Learners';

    if (!setMOCWhenCopied.current && isInformationForLearners) {
      setFieldValue('isMoc', copiedActParams?.isMoc ? 'isMoc-true' : 'isMoc-false');
      if (copiedActParams?.isMoc) {
        setFieldValue('boardMocDetails', copiedActParams.boardMocDetails);
        const boardIds = keys(copiedActParams.boardMocDetails);
        setFieldValue('boardMocDetailsSelection', boardIds);
      }
      setMOCWhenCopied.current = true;
    }

    if (!runOnceCme.current && isInformationForLearners) {
      setFieldValue(
        'includeInCmeFinder',
        copiedActParams.includeInCmeFinder ? 'includeInCmeFinder-true' : 'includeInCmeFinder-false',
      );
      runOnceCme.current = true;
    }
  }, [activeStep, values.boardMocDetails]);

  // For Information for Learners set defaults on step load for credits, ja and pharma
  useEffect(() => {
    if (
      activeStep &&
      activeStep.label === 'Information for Learners' &&
      copiedActParams &&
      !runOnceInforForLearners.current &&
      copiedActParams.credits
    ) {
      if (isJa || isNars) {
        const creditKeys = keys(copiedActParams.credits);
        if (creditKeys.length > 0) {
          setFieldValue('credits', copiedActParams.credits);
          creditKeys.forEach((keys) => {
            setFieldValue(`credits.credits${keys}`, true);
            setFieldValue(`credits.${keys}`, copiedActParams.credits[keys]);
          });
        }
      } else {
        setFieldValue('credits', copiedActParams.credits);
        setFieldValue(`credits.credits${amaCreditId}`, true);
        setFieldValue(`credits.${amaCreditId}`, copiedActParams.credits[amaCreditId]);
      }

      if (copiedActParams.moCCreditTypes !== undefined) {
        const index = copiedActParams.moCCreditTypes.findIndex(
          (board) => board.boardName === 'American Board of Anesthesiology',
        );

        if (index !== -1) {
          const foundBoard = boards[index];
          setFieldValue('moCCreditTypes', foundBoard);
        }
      }

      runOnceInforForLearners.current = true;
    }
  }, [
    activeStep,
    isJa,
    isNars,
    values.isMoc,
    values.boardMocDetails,
    values.boardRemsDetails,
    values.isMips,
    values.isRestrictedAudience,
    values.isRems,
    values.contentTags,
  ]);

  //  Control for state tagging
  useEffect(() => {
    if (isPharmacyCreditsChecked) {
      const { targetAudience } = values;
      const isTargetAudiencePharmacy = targetAudience?.includes('isTargetAudiencePharmacy') ?? false;
      const isTargetAudiencePharmacyTech = targetAudience?.includes('isTargetAudiencePharmacyTech') ?? false;
      const bothAreTrue = (isTargetAudiencePharmacy && isTargetAudiencePharmacyTech) ?? false;

      const mappings = [
        {
          selector: '[data-sub-group-id="pharmacyRecertificationTaxonomyTerms"]',
          condition:
            (isPharmacyRecert && (bothAreTrue || isTargetAudiencePharmacy || isTargetAudiencePharmacyTech)) ?? false,
        },
        {
          selector: '[id="Pharmacists"]',
          condition: (isTargetAudiencePharmacy && isPharmacyRecert) ?? false,
        },
        {
          selector: '[id="Pharmacists Prescriptive Authority"]',
          condition: (isTargetAudiencePharmacy && isPharmacyRecert) ?? false,
        },
        {
          selector: '[id="Pharmacy Technicians"]',
          condition: (isTargetAudiencePharmacyTech && isPharmacyRecert) ?? false,
        },
      ];

      // Disables the 'Yes' option for 'hasPharmacyRecertifications' based on whether a target audience is selected.
      // Also makes sure that deselecting all target audiences resets the option back to 'No'.
      const recertElement = document.querySelector(`[id=${HasPharmRecertTemplateOptions.yesPharmCert1}]`);
      const recertRootElement = recertElement?.parentElement?.parentElement;
      const previousRecertValue = values.hasPharmacyRecertifications;
      if (bothAreTrue || isTargetAudiencePharmacy || isTargetAudiencePharmacyTech) {
        recertElement?.parentElement?.classList.remove('disabled-radio-button');
        recertRootElement?.classList.remove('disabled-radio-button');
      } else {
        if (previousRecertValue !== HasPharmRecertTemplateOptions.notSurePharmCert2) {
          setFieldValue('hasPharmacyRecertifications', HasPharmRecertTemplateOptions.noPharmCert0);
        }
        recertElement?.parentElement?.classList.add('disabled-radio-button');
        recertRootElement?.classList.add('disabled-radio-button');
      }

      // Disables the 'Yes' option for 'hasPharmacyContentTags' based on whether a target audience is selected.
      // Also makes sure that deselecting all target audiences resets the option back to 'No'.
      const contentTagElement = document.querySelector(`[id=${HasPharmContentTagTemplateOptions.yesPharmContentTag1}]`);
      const contentTagRootElement = contentTagElement?.parentElement?.parentElement;
      const previousContentTagValue = values.hasPharmacyContentTags;
      if (bothAreTrue || isTargetAudiencePharmacy || isTargetAudiencePharmacyTech) {
        contentTagElement?.parentElement?.classList.remove('disabled-radio-button');
        contentTagRootElement?.classList.remove('disabled-radio-button');
      } else {
        if (previousContentTagValue !== HasPharmContentTagTemplateOptions.notSurePharmContentTag2) {
          setFieldValue('hasPharmacyContentTags', HasPharmContentTagTemplateOptions.noPharmContentTag0);
        }
        contentTagElement?.parentElement?.classList.add('disabled-radio-button');
        contentTagRootElement?.classList.add('disabled-radio-button');
      }

      mappings.forEach(({ selector, condition }) => {
        const element = document.querySelector(selector);
        if (condition) {
          element?.classList.remove('hidden');
        } else {
          element?.classList.add('hidden');
        }
      });

      if (!values.hasPharmacyRecertifications?.endsWith('1')) {
        values.pharmacyRecertificationTaxonomyTerms = [];
      }
      if (!targetAudience?.length) {
        setFieldValue('pharmacyRecertificationTaxonomyTerms', []);
      }
    }
  }, [
    isPharmacyCreditsChecked,
    values.credits,
    values.targetAudience,
    values.hasPharmacyRecertifications,
    isPharmacyRecert,
  ]);

  //  Control for recert req tagging
  useEffect(() => {
    if (isPharmacyCreditsChecked) {
      const hasPharmacyRecertifications = document.querySelector(
        '[data-sub-group-id="hasPharmacyRecertifications"]',
      ) as HTMLInputElement;
      const hasPharmacyRecertificationsCheckBox = document.querySelector(
        `[name="hasPharmacyRecertifications"][value=${HasPharmRecertTemplateOptions.noPharmCert0}]`,
      ) as HTMLInputElement;
      if (hasPharmacyRecertifications) {
        if (hasPharmacyRecertificationsCheckBox && !hasPharmacyRecertificationsCheckBox.checked) {
          setTimeout(() => {
            hasPharmacyRecertificationsCheckBox.checked = true;
          }, 400);
        }
      }
    }
    if (doesAMACreditHaveValue) {
      setFieldValue('hasStateContentTags', 'stateContentTagNotSure0');
    } else {
      setFieldValue('hasStateContentTags', null);
    }
  }, [values.credits]);

  useEffect(() => {
    const performAsyncOperation = () => {
      if (copiedActParams && activeStep && activeStep.label === 'MOC' && !runOnceMOCS.current) {
        const mrdKeys = Object.keys(copiedActParams.boardMocDetails);
        if (mrdKeys.length > 0) {
          for (const mrd of mrdKeys) {
            const practiceIds = copiedActParams.boardMocDetails[mrd].practiceIds ?? [];
            setFieldValue(`boardMocDetails.${mrd}.practiceIds`, practiceIds);
            setFieldValue(`boardMocDetails.${mrd}.mocPointsGiven`, copiedActParams.boardMocDetails[mrd].mocPointsGiven);
            setFieldValue(`boardMocDetails.${mrd}.optionSelectionId`, '');
            // we only care about copying ABA content tags
            if (mrd === '83297783-9fe4-4ab5-9fd2-dafbf7aa6d05') {
              const contentOutlineIds = copiedActParams.boardMocDetails[mrd].contentOutlineIds ?? [];
              setFieldValue(`boardMocDetails.${mrd}.contentOutlineIds`, contentOutlineIds);
            }

            const typesOfCreditIds = copiedActParams.boardMocDetails[mrd].typesOfCreditIds ?? [];
            if (typesOfCreditIds.length > 0) {
              setFieldValue(`boardMocDetails.${mrd}.typesOfCreditIds`, typesOfCreditIds);
            }
          }
        }
        runOnceMOCS.current = true;
      }
    };

    const handleAsyncOperation = () => {
      setTimeout(() => {
        performAsyncOperation();
      }, 450);
    };

    handleAsyncOperation();
  }, [activeStep, copiedActParams, values.boardMocDetails]);

  // This whole `setActiveFieldClass` is not optimal and needs to be refactored. This is happening on every
  // formik state change.
  const setActiveFieldClass = () => {
    // TODO we should be using refs here instead of querying the DOM
    const allSections = document.querySelectorAll('.form-section');
    const allGroups = document.querySelectorAll('.form-group');
    const activeNodes = document.querySelectorAll(`[data-template-id="${activeStep?.label}"]`);

    allSections.forEach((node) => node.classList.remove('active'));
    allGroups.forEach((node) => node.classList.remove('active'));
    activeNodes.forEach((node) => node.classList.add('active'));
    // The following cannot be done via the template because it comes from taxonomy.
    // Hide/Show these group when the page renders.
    if (doesAMACreditHaveValue || (isJa && doesIPCECreditHaveValue)) {
      document.querySelector('[data-group-id="includeInCmeFinderGroup"]')?.classList.remove('hidden');
      document.querySelector('[data-group-id="isRestrictedAudienceGroup"]')?.classList.remove('hidden');
      document.querySelector('[data-group-id="detailsUrlGroup"]')?.classList.remove('hidden');
      // JA is hasStateContentTags, PARS is HasStateContentTags - just operate on both
      document.querySelector('[data-group-id="hasStateContentTags"]')?.classList.remove('hidden');
      document.querySelector('[data-group-id="HasStateContentTags"]')?.classList.remove('hidden');
    } else if (isNars) {
      // For NARS the includeInCmeFinderGroup should be visible but not the top level includeInCmeFinder input.
      document.querySelector('[data-group-id="includeInCmeFinderGroup"]')?.classList.remove('hidden');
      document.querySelector('[data-group-id="hasStateContentTags"]')?.classList.add('hidden');
      document.querySelector('[data-group-id="HasStateContentTags"]')?.classList.add('hidden');
    } else {
      document.querySelector('[data-group-id="includeInCmeFinderGroup"]')?.classList.add('hidden');
      document.querySelector('[data-group-id="isRestrictedAudienceGroup"]')?.classList.add('hidden');
      document.querySelector('[data-group-id="detailsUrlGroup"]')?.classList.add('hidden');
      // JA is hasStateContentTags, PARS is HasStateContentTags - just operate on both
      document.querySelector('[data-group-id="hasStateContentTags"]')?.classList.add('hidden');
      document.querySelector('[data-group-id="HasStateContentTags"]')?.classList.add('hidden');
    }

    if (isPharmacyCreditsChecked) {
      document.querySelector('[data-group-id="pharmacyQuestions"]')?.classList.remove('hidden');
      if (isPharmacyAbbreviationL) {
        document.querySelector('[data-question="pharmacyLiveDates"]')?.parentElement?.classList.remove('hidden');
      } else {
        document.querySelector('[data-question="pharmacyLiveDates"]')?.parentElement?.classList.add('hidden');
      }

      if (!isPharmacyRecert) {
        document.querySelector('[data-sub-group-id="pharmacyRecertificationTaxonomyTerms"]')?.classList.add('hidden');
      } else {
        document
          .querySelector('[data-sub-group-id="pharmacyRecertificationTaxonomyTerms"]')
          ?.classList.remove('hidden');
      }
    } else {
      document.querySelector('[data-group-id="pharmacyQuestions"]')?.classList.add('hidden');
    }
  };
  // trigger this once when the form section loads so initial values get evaluated (ie. initally hiding state content tags since the default is no AMA credit)
  useEffect(() => {
    setActiveFieldClass();
  }, []);

  useEffect(() => {
    if (activity.length > 1) {
      setIsDisabled(true);
    }
  }, [activity]);

  // If more than 1 current activity after first submission, disable all inputs on the first step of Add Activity Form.
  useEffect(() => {
    if (values.recurring === 'recurring-true' && activity.length > 1) {
      const inputNodes = document.querySelectorAll('[data-template-id="Basics"] input');
      const formInputNodes = document.querySelectorAll('[data-template-id="Basics"] .form-input');
      inputNodes.forEach((node: Element): void => node.setAttribute('disabled', 'true'));
      formInputNodes.forEach((node: Element): void => node.classList.add('disabled'));
    } else {
      const inputNodes = document.querySelectorAll('[data-template-id="Basics"] input');
      const formInputNodes = document.querySelectorAll('[data-template-id="Basics"] .form-input');
      inputNodes.forEach((node: Element): void => node.removeAttribute('disabled'));
      formInputNodes.forEach((node: Element): void => node.classList.remove('disabled'));
    }
  }, [values.recurring, activity]);

  // Show the pharmacy questions when the pharmacy checkbox is checked.
  // Cannot be done via the template because it comes from taxonomy.
  useEffect(() => {
    if (isPharmacyCreditsChecked) {
      document.querySelector('[data-group-id="pharmacyQuestions"]')?.classList.remove('hidden');
      // If is-recurring was selected, don't show a number input, show a message instead.
      if (values.recurring === 'recurring-true') {
        const node = document.querySelector('[data-question="pharmacySequenceNumber"]');
        if (node) {
          const message = document.createElement('div');
          message.setAttribute('class', 'caption-text form-input');
          message.innerHTML =
            'For recurring activities, the sequence number for the UAN will be autogenerated for each activity instance.';
          node.parentNode.replaceChild(message, node);
        }
      }
      // set Default show one date field on Pharmacy live date
      const isPharmacyLiveDate = values.pharmacyLiveDates;
      if ((!copiedActParams && !isPharmacyLiveDate) || isPharmacyLiveDate?.length === 0) {
        setFieldValue('pharmacyLiveDates', ['']);
      }
    } else {
      document.querySelector('[data-group-id="pharmacyQuestions"]')?.classList.add('hidden');
    }
  }, [isPharmacyCreditsChecked, values.credits, values.recurring]);

  // Since the Pharmacy Questions' visibility is custom, we have to rely on this hook to reset fields on hide
  useEffect(() => {
    if (!isPharmacyCreditsChecked) {
      pharmacyQuestionFields.forEach((fieldKey) => setFieldValue(fieldKey, initialValues[fieldKey]));
    }
  }, [isPharmacyCreditsChecked]);

  // 4793: Since the CME Passport Questions' visibility is custom, we have to rely on this hook to reset fields on hide
  useEffect(() => {
    if ((!copiedActParams && doesAMACreditHaveValue) || (doesIPCECreditHaveValue && isJa)) {
      setFieldValue('includeInCmeFinder', 'includeInCmeFinder-true');
    }

    if ((!copiedActParams && !doesAMACreditHaveValue) || (!doesIPCECreditHaveValue && isJa)) {
      setFieldValue('includeInCmeFinder', 'includeInCmeFinder-true');

      // Has neither - needs to default includeInCmeFinder to true
      if (!copiedActParams && !doesAMACreditHaveValue && !doesIPCECreditHaveValue) {
        cmePassportQuestionFields.forEach((fieldKey) => setFieldValue(fieldKey, initialValues?.[fieldKey]));
        setFieldValue('includeInCmeFinder', 'includeInCmeFinder-true');
      }
    }
  }, [doesAMACreditHaveValue, isJa, doesIPCECreditHaveValue]);

  // Default to next available sequence number if one was provided via API
  // Only add to the model if the pharmacy credits are checked
  // Remove from the model when pharmacy credits is unchecked
  useEffect(() => {
    const isSequenceNumberValid = isNumber(values?.pharmacySequenceNumber);
    const isPharmacyCreditsChecked = !!values?.credits?.[`credits${pharmacyCreditsTermId}`];
    if (!isSequenceNumberValid && !!isPharmacyCreditsChecked) {
      setFieldValue('pharmacySequenceNumber', nextSequenceNumber);
    }
    if (!copiedActParams && isSequenceNumberValid && !isPharmacyCreditsChecked) {
      setFieldValue('pharmacySequenceNumber', undefined);
    }
  }, [nextSequenceNumber, values?.credits]);

  useEffect(() => {
    if (activityTemplate)
      setGeneratedFields(
        FormService.buildForm({
          activeStepLabel: activeStep?.label,
          activities: activity,
          allMocBoards: allMocBoards ?? [],
          allRemsBoards: allRemsBoards ?? [],
          allContentTags: allContentTags ?? [],
          amaCreditValue: values?.credits?.[amaCreditId] as number,
          configurationDictionary,
          configuredMocBoards,
          configuredRemsBoards,
          formMeta: activityTemplate,
          formikBag: {
            errors,
            handleChange: onSubmit,
            initialValues: initialValues,
            setFieldValue,
            touched,
            values,
          },
          rollupOrganizationEnum,
          selectedRemsBoardIds: values?.supportedRemsIds,
          selectedSpecialtyBoardIds: values?.boardMocDetailsSelection ?? [],
          terms,
        }),
      );

    let hasContentTag: HasStateContentTags;
    if (rollupOrganizationEnum === RollupOrganizationEnums.NARS) {
      hasContentTag = HasStateContentTags.No;
    } else {
      hasContentTag = values.hasStateContentTags?.endsWith('1') ? HasStateContentTags.Yes : HasStateContentTags.No;
    }

    const showContentTagging =
      (doesAMACreditHaveValue && hasContentTag === HasStateContentTags.Yes) ||
      (isPharmacyCreditsChecked && values.hasPharmacyContentTags?.endsWith('1'));
    if (values.isMoc || values.isRems || showContentTagging)
      updateCollaborationSteps(values.isMoc, values.isRems, showContentTagging);

    outcomeMeasuredTerms.map((value: string): string => values[value]);
  }, [
    // NOTE add values here that need to conditionally render visible, required, or editable so that the form will rerender when values change
    activeStep?.label,
    activityTemplate,
    terms,
    values.commercialSupportSourceKind,
    values.credits,
    values.hasCommercialSupport,
    values.includeInCmeFinder,
    values.isJointlyProvided,
    values.isMips,
    values.isMoc,
    values.isRems,
    values.markedForCommendationCriteria,
    values.pharmacyLiveDates,
    values.recurring,
    values.typeId,
    values.locationType,
    values.hasStateContentTags,
    values.hasPharmacyContentTags,
    values.hasPharmacyRecertifications,
    values.isIndividualActivity,
    values.iaOrganizationName,
    rollupOrganizationEnum,
    nextSequenceNumber,
  ]);

  // On selecting a board, load it's config and disable submission
  useEffect(() => {
    const boardSelections: string[] = values?.boardMocDetailsSelection || [];
    const remsSelections: string[] = values?.supportedRemsIds || [];

    const missingBoardConfigs: string[] = [...boardSelections, ...remsSelections]?.filter(
      (boardId: string): boolean => !configurationDictionary?.[boardId],
    );
    const boardsNotInTransit: string[] = difference(missingBoardConfigs, requestedBoardIds);
    if (boardsNotInTransit?.length) {
      setIsLoadingBoards(true);
      setRequestedBoardIds(uniq([...requestedBoardIds, ...boardsNotInTransit]));
      Promise.all(
        boardsNotInTransit.map(async (boardId: string) => await dispatch(getBoardById(boardId))),
      ).finally(() => setIsLoadingBoards(false));
    }
  }, [configurationDictionary, dispatch, values?.boardMocDetailsSelection, values?.supportedRemsIds]);

  useEffect(() => {
    setShowGlobalError(false);
  }, [activeStep?.label]);

  // Clear out the fields from the model set to default fields(omit slow) when typeId changes and is not `RSS` or `Live course`.
  useEffect(() => {
    // Skip the effect on first render
    if (initialRender.current && copiedActParams) {
      initialRender.current = false;
      return;
    }

    const isLiveCourseOrRSS = values.typeId === liveCourseId || values.typeId === rssId;
    if (!isLiveCourseOrRSS) {
      setFieldValue('recurring', 'recurring-false');
      setFieldValue('locationType', []);
    }

    if (isLiveCourseOrRSS) {
      setFieldValue('recurring', 'recurring-false');
    }
  }, [liveCourseId, rssId, values.typeId]);

  useEffect(() => {
    // When updating, if recurring was dropped from the model, keep only the first date/location entry
    if ((!values.recurring || values.recurring?.includes('false')) && values?.tempDatesAndLocation?.length > 1) {
      const singleIndexDatesAndLocation = values.tempDatesAndLocation[0] ?? {};
      setFieldValue('tempDatesAndLocation', [singleIndexDatesAndLocation]);
    }
  }, [values.recurring, values.tempDatesAndLocation, setFieldValue]);

  // Taxonomy Initialization Hook
  useEffect(() => {
    termSetIds.forEach((id) => {
      if (!baseTerms[id] && isAuthenticated) {
        dispatch(getTaxonomyTermById(id));
      }
    });
  }, []);

  // 4793 - use this hook to set include cme value to false at first step.
  useEffect(() => {
    if (!copiedActParams && isFirstStep) {
      setFieldValue('includeInCmeFinder', 'includeInCmeFinder-false');
    }

    // NARS includeInCmeFinder should always be false.
    if (isNars) {
      setFieldValue('includeInCmeFinder', 'includeInCmeFinder-false');
    }
  }, [values.includeInCmeFinder]);

  // Get all boards
  useEffect(() => {
    if (!boards) {
      dispatch(getAllBoards());
    }
  }, [boards, dispatch, isAuthenticated]);

  const handleResetForm = async () => {
    // Reset the currentActivities to a clean state.
    dispatch(clearCurrentActivities());

    // Reset the form values.
    resetForm({
      values: defaultAddFormProps,
    });
    goToStep('Basics');
  };

  setActiveFieldClass();

  const shouldShowLocationInformation = !!values?.locationType?.includes('InPerson');

  const shouldShowRepeater: boolean = values?.recurring === 'recurring-true';
  const buttonText = (): string => {
    const isFirstStep: boolean = activeStep?.label === 'Basics';

    if (isFirstStep && activity.length > 1) return 'Continue';
    if (isSubmitStep) return 'Save Activity';

    return 'Save Draft and Continue';
  };

  return (
    <FormikProvider value={formik}>
      <Stepper handleClick={goToStep} steps={formSteps} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <div className="form-title-container">
          <div className="title">
            <div className="eyebrow">{formTitle}</div>
            <h4>
              {activityTemplate
                .find((step: IFormSection): boolean => step.Id === activeStep?.label)
                ?.Subtitle?.replace("'Activity Name'", values.title)}
              <Button className="btn-faq" onClick={() => dispatch(openRail())}>
                FAQ
              </Button>
            </h4>
            {activeStep?.label === 'REMS' && (
              <p>
                As a service to the FDA's Risk Evaluation and Mitigation Strategies Program, accredited providers can
                register activities in support of REMS.
              </p>
            )}
            {activeStep?.label === 'MOC' ? (
              <p className={styles['board-subheading']}>
                To add more certifying boards
                <button
                  title="Click to return to Information for Learners"
                  className={styles['cert-board-button']}
                  onClick={() => goToStep('Information for Learners')}
                >
                  click here
                </button>
                .
              </p>
            ) : null}
          </div>
        </div>
        {!isValid && showGlobalError && (
          <div className="error">
            <div className="message">
              <WarningRounded /> {formError}
            </div>
          </div>
        )}
        {/* TODO Need Form Alert Props */}
        {!isValid && false && (
          <div className="info">
            <div className="message">
              <InfoRounded /> {formAlert}
            </div>
          </div>
        )}
        {isNars && currentUserOrganization.isApproverOfActivities && (
          <div data-template-id="Basics" className="form-group">
            <InputBlock
              errors={errors}
              name="isIndividualActivity"
              suppressLabel
              title="Is this an Individual Activity?"
              touched={touched}
            >
              <div className={styles.checkbox}>
                <FormikCheckboxField
                  formikKey="isIndividualActivity"
                  name="Yes"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('isIndividualActivity', e.target.checked)
                  }
                  checked={values.isIndividualActivity === true}
                />
              </div>
            </InputBlock>
            {values.isIndividualActivity && (
              <InputBlockWithErrorTouchCheck
                errors={errors}
                name="iaOrganizationName"
                required
                suppressLabel
                title="What's the name of the individual activity organization?"
                touched={touched}
              >
                <Field
                  aria-label="What's the name of the individual activity organization?"
                  id="iaOrganizationName"
                  name="iaOrganizationName"
                  placeholder="Enter Individual Activity Organization Name"
                  type="text"
                />
              </InputBlockWithErrorTouchCheck>
            )}
          </div>
        )}
        <div data-template-id="Basics" className="form-group">
          <InputBlockWithErrorTouchCheck
            errors={errors}
            name="title"
            required
            suppressLabel
            title="What's the name of the activity"
            touched={touched}
          >
            <Field
              aria-label="Activity Name"
              disabled={isDisabled}
              id="title"
              name="title"
              placeholder="Enter Activity Name"
              type="text"
            />
          </InputBlockWithErrorTouchCheck>
        </div>
        <div data-template-id="Basics" className="form-group">
          <InputBlockWithErrorTouchCheck
            ariaLabel="Activity Format"
            required
            suppressLabel
            errors={errors}
            touched={touched}
            name="typeId"
            role="radiogroup"
            title="What is the activity format?"
          >
            <Box mt={2}>
              {orderBy(activityTypes, 'displayOrder').map(
                (type: ActivityType): ReactElement => {
                  const { description, id, title } = type;
                  return (
                    <Box key={id} mt={2}>
                      <Grid container>
                        <Grid item>
                          <label className="form-input-radio" htmlFor={id}>
                            <Field
                              disabled={isDisabled}
                              id={id}
                              name="typeId"
                              type="radio"
                              value={id}
                              aria-invalid={!!errors?.typeId?.length}
                            />
                            <span className="checkmark" />
                            <Typography variant="srOnly">{title}</Typography>
                          </label>
                        </Grid>
                        <Grid item xs={11}>
                          <div className="label-text">{title}</div>
                          <div className="caption-text">{description}</div>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                },
              )}
            </Box>
          </InputBlockWithErrorTouchCheck>
        </div>
        {generatedFields}
        <div data-template-id="Basics" className="form-group">
          <LocationDetailsRepeater
            isDisabled={isDisabled}
            formikKey="tempDatesAndLocation"
            shouldShowLocationInformation={shouldShowLocationInformation}
            shouldShowRepeater={shouldShowRepeater}
          />
        </div>
        {activeStep?.label === 'Complete' && (
          <div data-template-id="Complete" className="form-group">
            <h3>{activity.length > 1 ? 'Activities' : 'Activity'} successfully added</h3>
            <CompletedActivityCard
              currentActivities={activity}
              configurationDictionary={configurationDictionary}
              handleAddAnotherActivity={handleResetForm}
              values={values}
            />
          </div>
        )}
        {!isLastStep && (
          <ButtonRow
            ctaLabel={buttonText()}
            isSubmitting={isSubmitting || isLoadingBoards}
            isValid={isValid && dirty}
            onBackClick={(): void => {
              decrementStep();
              setErrors({});
            }}
            onClick={handleSubmit}
            showBack={!isFirstStep}
          />
        )}
        {isLastStep && (
          <Typography variant="srOnly">
            <button tabIndex={-1}>SR only</button>
          </Typography>
        )}
      </form>
    </FormikProvider>
  );
};

const AddActivityForm = withFormik<IActivityProps, ActivityFormStateValues>({
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    const { onSubmit } = props;
    setSubmitting(true);
    try {
      await onSubmit(values);
    } catch (e) {
      setStatus(e);
    }
    setSubmitting(false);
  },
  enableReinitialize: false,
  mapPropsToValues: (props: IActivityProps): ActivityFormStateValues => {
    const { copiedActParams, dictionaries, processedInitValues } = props;
    if (!copiedActParams || processedInitValues.current) return null;
    processedInitValues.current = true;

    const supportSources = ['Monetary', 'NonMonetary'];
    if (copiedActParams) {
      const {
        boardMocDetails,
        boardRemsDetails,
        credits,
        targetAudience,
        status,
        outcomesMeasured,
        locationType,
        hasCommercialSupport,
        title,
        ...restActivity
      } = copiedActParams;

      let commercialInKindSupportSources: IInKindSupportSourceOption[] = [];
      let commercialSupportSourceKind: string[] = [];
      let commercialMonetarySupportSources: IMonetarySupportSourceOption[] = [];

      const inKindSupportSources: CommercialSupportSource[] = copiedActParams.commercialSupportSources.filter(
        ({ hasInKindSupport, source }): boolean => hasInKindSupport && source !== '',
      );
      const monetarySupportSources: CommercialSupportSource[] = copiedActParams.commercialSupportSources.filter(
        ({ hasInKindSupport }): boolean => hasInKindSupport === false,
      );

      commercialMonetarySupportSources = monetarySupportSources.map(recreateMonetarySupportSourceOption);

      // Default value for 'Monetary Amount' set to NULL, not 0, to maintain consistent value for absent Monetary Amount
      commercialMonetarySupportSources &&
        commercialMonetarySupportSources.forEach((msp) => {
          msp.amountGiven = null;
        });

      commercialInKindSupportSources = inKindSupportSources.map(recreateInKindSupportSourceOption);

      commercialSupportSourceKind = supportSources.filter((sourceKind): boolean => {
        if (sourceKind === 'Monetary') {
          return !!monetarySupportSources?.length;
        }
        if (sourceKind === 'NonMonetary') {
          return !!inKindSupportSources?.length;
        }
        return false;
      });

      resetObjectAttributes(boardRemsDetails, [
        ['isAttested', false],
        ['remsActivityId', ''],
      ]);

      const deepCloneBoardMocDetails: IDictionary<BoardMocDetails> = cloneDeep(boardMocDetails);
      const deepCloneBoardRemsDetails: IDictionary<IBoardRemsDetails> = cloneDeep(boardRemsDetails);

      const boardIds = keys(deepCloneBoardMocDetails);
      const remsIds = keys(deepCloneBoardRemsDetails);
      const targetAudienceList = targetAudience?.map((audience) => lowerFirst(audience)) || [];

      const { creditTypesDictionary } = dictionaries;
      const allCreditTypeKeys: string[] = keys(creditTypesDictionary);
      const creditKeys: string[] = keys(credits);
      const creditsCheckboxes: IDictionary<boolean> = {};
      const creditsInitialValues: IDictionary<number> = {};

      creditKeys?.forEach((key: string): void => {
        creditTypesDictionary[key]
          ? (creditsCheckboxes[`credits${key}`] = true)
          : (creditsCheckboxes[`credits${key}`] = false);
      });

      allCreditTypeKeys?.forEach((key: string): void => {
        if (typeof credits[key] === 'number') creditsInitialValues[key] = credits[key];
      });

      const transformedLocationType = copiedActParams!.locationType?.map((location) =>
        location?.replace(' ', '')?.replace('-', '')?.replace('S', 's'),
      );

      const dateLoc: IDatesAndLocation = {
        city: '',
        country: '',
        endDate: null,
        postalCode: '',
        startDate: null,
        stateOrProvince: '',
      };
      const tdl: IDatesAndLocation[] = [dateLoc];

      return {
        ...restActivity,
        tempDatesAndLocation: tdl,
        boardMocDetails: deepCloneBoardMocDetails,
        boardMocDetailsSelection: boardIds,
        boardRemsDetails: deepCloneBoardRemsDetails,
        commercialInKindSupportSources,
        commercialMonetarySupportSources,
        commercialSupportSourceKind,
        tdl,
        outcomesMeasured: outcomesMeasured,
        recurring: 'recurring-false',
        credits: {
          ...creditsInitialValues,
          ...creditsCheckboxes,
        },
        internalId: '',
        markedForCommendationCriteria: 'markedForCommendationCriteria-false',
        commendationCriteriaIds: [],
        hasCommercialSupport: copiedActParams?.hasCommercialSupport
          ? 'hasCommercialSupport-true'
          : 'hasCommercialSupport-false',
        includeInCmeFinder: copiedActParams?.includeInCmeFinder
          ? 'includeInCmeFinder-true'
          : 'includeInCmeFinder-false',
        isIpce: copiedActParams?.isIpce ? 'isIpce-true' : 'isIpce-false',
        isJointlyProvided:
          copiedActParams?.isJointlyProvided === true
            ? 'joint'
            : copiedActParams?.isJointlyProvided === false
            ? 'direct'
            : undefined,
        isMips: copiedActParams?.isMips || copiedActParams?.isMips ? 'isMips-true' : 'isMips-false',
        isMoc: copiedActParams?.isMoc || copiedActParams?.isMoc ? 'isMoc-true' : 'isMoc-false',
        isPharmacyCertificateProgram: copiedActParams?.isPharmacyCertificateProgram
          ? 'isPharmacyCertificateProgram-true'
          : 'isPharmacyCertificateProgram-false',
        isRems: copiedActParams?.isRems || copiedActParams?.isRems ? 'isRems-true' : 'isRems-false',
        isRestrictedAudience:
          copiedActParams?.isRestrictedAudience === true
            ? 'limited'
            : copiedActParams?.isRestrictedAudience === false
            ? 'open'
            : '',
        locationType: transformedLocationType,
        title: copiedActParams.title,
        mocProgramAttestation: [],
        status: 'active',
        supportedRemsIds: remsIds ?? [],
        targetAudience: targetAudienceList,
        mocCreditDeadline: null,
        providerships: copiedActParams.providerships ?? [],
        learnerCounts: {},
        pharmacyLiveDates: [],
        pharmacyTypeId: copiedActParams.pharmacyTypeId ?? '',
        pharmacyTopicId: copiedActParams.pharmacyTopicId ?? '',
        pharmacySequenceNumber: undefined,
        isIndividualActivity: copiedActParams.isIndividualActivity,
        iAOrganizationName: copiedActParams.iAOrganizationName,
      };
    }
  },
  validateOnBlur: true,
  validateOnChange: false,
  validationSchema: ({ validationSchemas, activeStep }: IActivityProps) =>
    !!validationSchemas && isNumber(activeStep) && validationSchemas[activeStep],
})(AddActivityFormik);

export default AddActivityForm;

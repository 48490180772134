import { ReactElement, useEffect } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';

// Components
import Button from 'components/Button/Button';
import { FormikNumberField } from 'components/forms';

// Hooks.
import { useClickEvent, useLoadEvent } from 'hooks';

// Styles
import styles from './index.module.scss';

// Store
import { clearActivityDetail, getActivity, patchActivity } from 'store/activity/actions';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { activityDetailSelector, learnerCountsSelector } from 'store/activity/selectors';
import { allLearnerTypeTermsSelector } from 'store/taxonomy/selectors';
import { rollupOrganizationEnumSelector, organizationKindSelector } from 'store/user/selectors';

// Utils
import { filterTaxonomyByOrg } from 'globals/utils/filterTaxonomyByOrg';

// Types
import { TAXONOMY_LEARNER_TYPES_ROOT_ID } from 'core/constants';
import {
  Activity,
  IBoardSlim,
  IDictionary,
  ITaxonomyTerm,
  IUserOrganization,
  RollupOrganizationEnums,
} from 'core/models';
import { Enums } from 'core';
import { popToast } from 'store/toast/actions';
import { successToastOptions } from 'store/toast/constants';
import { boardSelector } from 'store/board/selectors';
import { getAllBoards } from 'store/board/actions';
import { StatusEnum } from 'core/enums';

type IProps = {
  activityId: string;
  id: string;
  title: string;
  endDate: Date;
  onClose?(): void;
  startDate: Date;
};

const UPDATE_LEARNERS_LABEL = 'Update Learners';
const ADD_INDIVIDUAL_LEARNERS_LABEL = 'Add Individual Learners';

export const UpdateLearnersForm = (props: IProps): ReactElement => {
  const { ButtonVariant } = Enums;
  const { activityId, endDate, id, onClose, startDate, title } = props;
  const dispatch = useDispatch();

  const fireLoadEvent = useLoadEvent({ PageName: 'Learner Modal', PageType: 'Activity' });
  const fireEvent = useClickEvent({ Event: 'Activity Learner Modal', EventCategory: 'Learner' });

  useEffect(() => {
    fireLoadEvent();
  }, [fireLoadEvent]);

  /**
   * App state
   */
  // Check to see if the user is JA.
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);

  // Get the current user's organization.
  const organization: IUserOrganization = useSelector(organizationKindSelector);

  // Get all the learnerTypeTerms from local storage.
  const allLearnerTypeTerms: ITaxonomyTerm[] = useSelector(allLearnerTypeTermsSelector);

  // Get the leaner counts from the activity detail to pre-populate the form.
  const learnerCounts: IDictionary<number> = useSelector(learnerCountsSelector);

  // Get the active activityDetail
  const activityDetail: Activity = useSelector(activityDetailSelector);

  // Get only the terms from the learnerTypes based on the user's org's rollup org
  const terms: ITaxonomyTerm[] = filterTaxonomyByOrg({ organization, terms: allLearnerTypeTerms });

  // Get the specialty boards
  const boards: IBoardSlim[] = useSelector(boardSelector);

  const formik = useFormik({
    initialValues: { ...learnerCounts },
    onSubmit: async (value, { setSubmitting }) => {
      // Event tracking.
      fireEvent({ EventAction: UPDATE_LEARNERS_LABEL });

      const valueWithDefault = Object.keys(value).reduce((acc, key) => {
        acc[key] = value[key] || 0; // If value is empty or falsy, set it to 0
        return acc;
      }, {});

      // Make the API request.
      const success = await dispatch(
        patchActivity(
          id,
          [{ op: 'replace', path: 'learnerCounts', value: { ...learnerCounts, ...valueWithDefault } }],
          async (): Promise<void> => {
            await dispatch(popToast({ ...successToastOptions, message: <>Total learner numbers have been saved.</> }));
          },
        ),
      );

      // We are done submitting.
      await setSubmitting(false);

      // Close the modal.
      if (success) {
        await onClose?.();
      }
    },
  });

  const { handleSubmit, isSubmitting, handleChange } = formik;

  // Setup on load (since patchActivity calls clearActivity/getActivity, which would cause us to call getActivity an extra time here if we ran when activityDetail got cleared).
  useEffect(() => {
    if (!activityDetail) {
      // Get the current activity.
      dispatch(getActivity(id));
      // Get the learnerTypes taxonomy terms.
      dispatch(getTaxonomyTermById(TAXONOMY_LEARNER_TYPES_ROOT_ID));
    }
    if (!boards) {
      dispatch(getAllBoards());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Unmount.
  useEffect(() => {
    // Clear the activity details on unmount.
    return () => dispatch(clearActivityDetail());
  }, [dispatch]);

  // Create a user friendly date string.
  const dates = `${moment(startDate).format('LL')} - ${moment(endDate).format('LL')}`;

  // Wait to have data before rendering anything.
  if (!learnerCounts)
    return (
      <div className={styles['loading-wrapper']}>
        <CircularProgress />
      </div>
    );

  // Required fields to determine if Add Learners is Disabled
  const status = activityDetail?.status;

  const isUpdateTotalLearnersDisabled = moment().isBefore(startDate, 'day');
  const isAddLearnersDisabled = isUpdateTotalLearnersDisabled || status === StatusEnum.DRAFT;
  const isRollUpOrganizationNars: boolean = rollupOrganizationEnum === RollupOrganizationEnums.NARS;
  /* Once an activity has been marked closed, the activity status updates to “closed”
    and the activity information, including aggregate learner counts, may not be changed. */
  const isClosed: boolean = status === StatusEnum.CLOSED;

  return (
    <div className={styles['learners-form-wrapper']}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{`Activity ID ${activityId} | ${dates}`}</p>
      {activityDetail.uanPharmacy && <p className={styles.description}>UAN: {activityDetail.uanPharmacy}</p>}
      {activityDetail.uanPharmacyTech && <p className={styles.description}>UAN: {activityDetail.uanPharmacyTech}</p>}
      <div className={styles['sub-heading']}>Update numbers of learners who completed this activity to date.</div>
      <FormikProvider value={formik}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles['form-content-wrapper']}>
            {terms.map(
              (term: ITaxonomyTerm): ReactElement => {
                const { id, name } = term;
                const label: string = rollupOrganizationEnum === RollupOrganizationEnums.JA ? name : `Total ${name}`;

                return (
                  <span className={styles.input} key={id}>
                    <FormikNumberField
                      disabled={isSubmitting || isClosed}
                      defaultValue={learnerCounts[id] || 0}
                      formikKey={id}
                      id={id}
                      step={1}
                      min={0}
                      name={id} // The backend expects this format.
                      onChange={handleChange}
                      variant="outlined"
                    />
                    <label htmlFor={id}>{label}</label>
                  </span>
                );
              },
            )}
          </div>
          <footer className={styles.footer}>
            <div className={styles.right}>
              {isClosed ? null : (
                <Button
                  startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
                  disabled={isSubmitting || isUpdateTotalLearnersDisabled}
                  type="submit"
                  variant={ButtonVariant.Primary}
                >
                  {UPDATE_LEARNERS_LABEL}
                </Button>
              )}
            </div>
            {!isRollUpOrganizationNars && (
              <div className={styles.right}>
                <Button
                  variant={ButtonVariant.Tertiary}
                  component={Link}
                  disabled={isSubmitting || isAddLearnersDisabled}
                  onClick={() =>
                    fireEvent({
                      EventAction: ADD_INDIVIDUAL_LEARNERS_LABEL,
                      EventLabel: `/activities/${id}/add-learner`,
                    })
                  }
                  to={`/activities/${id}/add-learner`}
                >
                  {ADD_INDIVIDUAL_LEARNERS_LABEL} &rarr;
                </Button>
              </div>
            )}
            {rollupOrganizationEnum === RollupOrganizationEnums.JA && (
              <div className={classNames([styles.right, styles.pharma])}>
                <a href="https://cpemonitor.acpe-accredit.org/Login.aspx" target="_blank" rel="noopener noreferrer">
                  <Button variant={ButtonVariant.Tertiary} disabled={isSubmitting}>
                    Add Pharmacy Learners &rarr;
                  </Button>
                </a>
              </div>
            )}
          </footer>
        </form>
      </FormikProvider>
    </div>
  );
};

import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@material-ui/core';
import { cloneDeep, keys, min, pick } from 'lodash';

// Components
import Faq from 'components/Faq/Faq';
import { IStepProps } from 'components/Stepper/Step';
import AddActivityForm from '../forms/addActivityForm';

// Hooks.
import { useClickEvent, useLoadEvent } from 'hooks';

// Store + Core
import {
  Activity,
  ActivityFormStateValues,
  ActivityType,
  AddActivityFormValues,
  BoardMocDetails,
  CreateActivityRequest,
  IActivityValidatorBlueprint,
  IBoardSlim,
  IDatesAndLocation,
  IDictionary,
  ITaxonomyDictionary,
  ITaxonomyTerm,
  RollupOrganizationEnums,
  UpdateActivityRequest,
} from 'core/models';
import {
  clearCurrentActivities,
  createActivities,
  getActivityTypes,
  getNextSequenceNumber,
  updateActivities,
  updateActivitiesFailureAction,
} from 'store/activity/actions';
import { closeRail } from 'store/rail/actions';
import { isRailOpenSelector } from 'store/rail/selectors';
import { getTaxonomyTermById } from 'store/taxonomy/actions';
import { organizationKindSelector, rollupOrganizationEnumSelector } from 'store/user/selectors';
import { StatusEnum } from 'core/enums';
import { popToast } from 'store/toast/actions';
import { errorToastOptions } from 'store/toast/constants';
import { TAGS_ENUM, TAXONOMY_ACTIVITY_CREDIT_ROOT_ID, TAXONOMY_FAQ_ROOT_ID } from 'core/constants';
import {
  activityErrorSelector,
  activityTypeLearnerReportGroupingsSelector,
  activityTypesSelector,
  activityTypesWithLearnerCreditValidationSelector,
  currentActivitiesSelector,
  currentActivityIdSelector,
} from 'store/activity/selectors';
import {
  amaCreditTermIdSelector,
  commercialSupportSourcesDictionarySelector,
  creditTypesDictionarySelector,
  pharmacyCreditTermIdSelector,
  taxonomyRootTermsSelector,
} from 'store/taxonomy/selectors';
import { boardSelector, configuredBoardsDictionarySelector, configuredBoardsSelector } from 'store/board/selectors';
import { BoardTypes, IBCTBoard, IBCTCreditType } from 'layouts/pages/bct/types';

// Utils
import { transformPayloadValues, transformUpdatePayloadValues } from './utils';
import { buildActivityValidationSchemas } from 'layouts/pages/activity/forms/validation/validationSchemas';
import { getTaxonomyChildByTag } from 'globals/utils/getTaxonomyChildByTag';

// Styles
import styles from 'components/forms/templates/UpdateLearnersForm/index.module.scss';
import { getAllowedBoardSelections } from '../utils';

export const AddActivityPage = (): ReactElement => {
  const [addActivityFormSteps, setAddActivityFormSteps] = useState<IStepProps[]>([]);
  const [activeStep, setActiveStep] = useState<IStepProps>(addActivityFormSteps[0]);
  const [amaTerm, setAmaTerm] = useState<ITaxonomyTerm>();
  const commercialSupportSourcesDictionary: IDictionary<ITaxonomyTerm> = useSelector(
    commercialSupportSourcesDictionarySelector,
  );
  const { isAuthenticated } = useAuth0();
  const filteredSteps = useMemo(
    () => addActivityFormSteps.filter(({ stepStatus, visible }) => stepStatus !== 'disabled' && visible),
    [addActivityFormSteps],
  );
  const stepNumber = useMemo(() => filteredSteps?.indexOf(activeStep) + 1, [filteredSteps, activeStep]);

  const fireLoadEvent = useLoadEvent({ PageName: 'Add Activity', PageType: 'Add Activity' });
  const fireClickEvent = useClickEvent({ Event: 'Add Activity', EventCategory: 'Add Activity' });

  const location = useLocation();
  const dispatch = useDispatch();
  const cpactparams: any = location.state;
  const processedValues = useRef(false);

  // Redux state.
  const activityId = useSelector(currentActivityIdSelector);
  const activityTypes: ActivityType[] = useSelector(activityTypesSelector);

  const boards = useSelector(boardSelector);
  const error: string = useSelector(activityErrorSelector);
  const isOpen: boolean = useSelector(isRailOpenSelector);
  const currentActivities: Activity[] = useSelector(currentActivitiesSelector);
  const terms: ITaxonomyDictionary = useSelector(taxonomyRootTermsSelector);
  const configuredBoardsDictionary: IDictionary<IBCTBoard> = useSelector(configuredBoardsDictionarySelector);

  // Redux actions.
  const dispatchAddActivities = useCallback(
    (activities: CreateActivityRequest[]) =>
      (dispatch(createActivities(activities)) as any) as Promise<Activity[] | null>,
    [dispatch],
  );
  const dispatchGetActivityTypes = useCallback(() => dispatch(getActivityTypes()), [dispatch]);
  const dispatchUpdateActivities = useCallback(
    (activities: UpdateActivityRequest[]) => (dispatch(updateActivities(activities)) as any) as Promise<boolean>,
    [dispatch],
  );
  const dispatchClearCurrentActivities = useCallback(() => dispatch(clearCurrentActivities()), [dispatch]);

  // State for Validation
  const currentOrganization = useSelector(organizationKindSelector);
  const configuredBoards = useSelector(configuredBoardsSelector);
  const creditTypesDictionary = useSelector(creditTypesDictionarySelector);

  const activityTypeGroups = useSelector(activityTypeLearnerReportGroupingsSelector);
  const activityTypesWithLearnerCreditValidation: string[] = useSelector(
    activityTypesWithLearnerCreditValidationSelector,
  );
  const amaCreditTerm = useSelector(amaCreditTermIdSelector);
  const pharmacyCreditTerm: ITaxonomyTerm = useSelector(pharmacyCreditTermIdSelector);

  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);

  const credDicts = {
    commercialSupportSourcesDictionary,
    creditTypesDictionary,
  };

  // TODO: Confirm this is the field BR36 refers to
  const orgId = currentOrganization?.businessId;
  const status = StatusEnum.DRAFT; // 'Add' is always in draft state

  const validationBlueprint: IActivityValidatorBlueprint = {
    activityTypeGroups,
    activityTypesWithLearnerCreditValidation,
    amaCreditTerm,
    configuredBoards,
    creditTypesDictionary,
    orgId,
    pharmacyCreditTerm,
    status,
  };

  const validationSchemas = buildActivityValidationSchemas(validationBlueprint, rollupOrganizationEnum);

  // Taxonomy Initialization Hook
  useEffect(() => {
    const termSetIds: string[] = [TAXONOMY_FAQ_ROOT_ID];
    termSetIds.forEach((id) => {
      if (!terms[id] && isAuthenticated) {
        dispatch(getTaxonomyTermById(id));
      }
    });
  }, []);

  // Clear the currentActivities in Redux when we dismount the component (or change route).
  useEffect(() => {
    return () => {
      dispatchClearCurrentActivities();
    };
  }, [dispatchClearCurrentActivities]);

  useEffect(() => {
    // Get the child ID for AMA Taxonomy
    setAmaTerm(
      getTaxonomyChildByTag({
        rootId: TAXONOMY_ACTIVITY_CREDIT_ROOT_ID,
        tag: TAGS_ENUM.ACTIVITY_CREDIT_TYPE__AMA_PRA_CATEGORY1,
        terms,
      }),
    );
  }, [terms]);

  const faqs: ITaxonomyTerm[] = (terms[TAXONOMY_FAQ_ROOT_ID] || {}).terms || [];
  const isJa = rollupOrganizationEnum === RollupOrganizationEnums.JA;
  let faqArea = '';
  let faqTitle = '';

  switch (activeStep && activeStep.label) {
    case 'Basics':
      faqArea = 'Basics';
      faqTitle = 'Basics FAQs';
      break;

    case 'Information for Learners':
      faqArea = 'Learners';
      faqTitle = 'Information for Learners FAQs';
      break;

    case 'Accreditation Details':
      faqArea = 'Accreditation';
      faqTitle = 'Accreditation Details FAQs';
      break;

    case 'State Content Tagging':
      faqArea = 'Content Tagging';
      faqTitle = 'State Content Tagging FAQs';
      break;

    case 'MOC':
      faqArea = 'MOC';
      faqTitle = 'MOC FAQs';
      break;

    case 'REMS':
      faqTitle = 'REMS FAQs';
      faqArea = 'REMS';
      break;
  }

  const findActiveStep = useCallback(() => {
    return (
      addActivityFormSteps.find((step) => step.stepStatus === 'active') ||
      addActivityFormSteps.find((step) => step.stepStatus === 'completed') ||
      addActivityFormSteps[0]
    );
  }, [addActivityFormSteps]);

  const determineIsStepPosition = useCallback(
    ({ step, positionAtEnd }: { step: IStepProps; positionAtEnd: number }): boolean => {
      // positionAtEnd is spots away from end, start with 1 being the last item; 0 indicates first item
      return (
        filteredSteps.indexOf(step) ===
        filteredSteps.length - (positionAtEnd === 0 ? filteredSteps.length : positionAtEnd)
      );
    },
    [filteredSteps],
  );

  const decrementStep = useCallback(() => {
    const previousStepIndex = activeStep ? filteredSteps.indexOf(activeStep) - 1 : 0;
    const previousStep = filteredSteps[previousStepIndex];
    activeStep.stepStatus = '';
    if (previousStep) {
      previousStep.stepStatus = 'active';
    }
    setAddActivityFormSteps(addActivityFormSteps);
    setActiveStep(previousStep);
    fireLoadEvent({ AddActivityStep: previousStepIndex + 1 }, true);
    window.scroll(0, 0);
    // TODO make this reactive
  }, [activeStep, addActivityFormSteps, filteredSteps, fireLoadEvent]);

  const incrementStep = useCallback((): void => {
    const nextStepIndex = activeStep ? filteredSteps.indexOf(activeStep) + 1 : 0;
    const nextStep: IStepProps = filteredSteps[nextStepIndex];
    const isLastStep: boolean = determineIsStepPosition({ positionAtEnd: 1, step: nextStep });
    activeStep.stepStatus = '';
    if (nextStep) {
      nextStep.stepStatus = isLastStep ? 'completed' : 'active';
    }
    setAddActivityFormSteps(addActivityFormSteps);
    setActiveStep(nextStep);
    fireLoadEvent(
      {
        AddActivityStep: nextStepIndex + 1,
        ...(isLastStep && { ActivityId: activityId, PageName: 'Add Activity Confirmation' }),
      },
      true,
    );
    window.scroll(0, 0);
  }, [activeStep, activityId, addActivityFormSteps, determineIsStepPosition, filteredSteps, fireLoadEvent]);

  const goToStep = useCallback(
    (label: string) => {
      const step = addActivityFormSteps.find((step) => step.label === label);
      const nextStepNumber = filteredSteps.indexOf(step) + 1;
      const isTargetAfterActive: boolean =
        addActivityFormSteps.indexOf(step) > addActivityFormSteps.indexOf(activeStep);
      const shouldNotStep: boolean = !step || isTargetAfterActive || step.stepStatus === 'disabled';
      if (shouldNotStep) {
        return;
      }
      const isLastStep = determineIsStepPosition({ positionAtEnd: 1, step });
      addActivityFormSteps.filter((step) => step.stepStatus !== 'disabled').forEach((step) => (step.stepStatus = ''));
      step.stepStatus = isLastStep ? 'completed' : 'active';
      setAddActivityFormSteps(addActivityFormSteps);
      setActiveStep(step);
      fireLoadEvent({ AddActivityStep: nextStepNumber }, true);
      window.scroll(0, 0);
    },
    [activeStep, addActivityFormSteps, determineIsStepPosition, filteredSteps, fireLoadEvent],
  );

  // Construct the formik submission payload.
  const handleAdd = useCallback(
    // Asynchronous function to handle form submission and process the provided values.
    async (values: ActivityFormStateValues) => {
      // Transform the updated values into the payload format
      const submitValues = transformPayloadValues(values);
      const { title, typeId, startDate, endDate } = submitValues[0];
      const hasAllRequiredFields = title && typeId && startDate && endDate;
      const isOnStep1 = hasAllRequiredFields && !currentActivities.length;
      // If we are back on the first step and we have more than 1 activity, no API is needed.
      if (activeStep?.label === 'Basics' && currentActivities.length > 1) {
        return incrementStep();
      }

      let ranWithoutError = false;
      if (hasAllRequiredFields && isOnStep1) {
        // if values are being copied over these initial fields need to be cleared out to pass form validation
        if (isOnStep1 && cpactparams && cpactparams.params) {
          submitValues[0].boardMocDetailsSelection = [];
          submitValues[0].boardMocDetails = {};
          submitValues[0].boardRemsDetails = {};
        }

        // Checking for the 4 required fields in order to continue.
        // Create an activity/activities.
        const newActivities = await dispatchAddActivities(submitValues);
        ranWithoutError = !!newActivities;
        // Silently get the sequence number
        if (ranWithoutError && isJa && !values.pharmacySequenceNumber) {
          await dispatch(getNextSequenceNumber(newActivities));
        }
      } else if (hasAllRequiredFields) {
        // Update activity/activities.
        const payload = transformUpdatePayloadValues({ currentActivities, submitValues });
        ranWithoutError = await dispatchUpdateActivities(payload);
      } else {
        const errorMessage = 'You have some incomplete fields';
        await dispatch(popToast({ ...errorToastOptions, message: <>{errorMessage}</> }));
        await dispatch(updateActivitiesFailureAction(errorMessage));
      }

      // Only step forward if there is no server error (can't use error from state, since we're in a closure here)
      if (ranWithoutError) {
        await incrementStep();
      }

      fireClickEvent({ EventAction: stepNumber, EventLabel: !error ? 'Success' : 'Fail' });
    },
    [
      activeStep,
      currentActivities,
      dispatch,
      dispatchAddActivities,
      dispatchUpdateActivities,
      error,
      fireClickEvent,
      incrementStep,
      isJa,
      stepNumber,
    ],
  );

  //  Filter out invalid boards automatically based off the allowed boards for the selected type id
  const filterMocValues = (values: any): void => {
    const deepCloneBoardMocDetails: IDictionary<BoardMocDetails> = cloneDeep(values.boardMocDetails);

    const allowedBoardSelections: IBoardSlim[] = getAllowedBoardSelections(boards, values.typeId);
    const allMocBoards: IBoardSlim[] = allowedBoardSelections?.filter(
      ({ type }: IBoardSlim): boolean => type === BoardTypes.CERTIFYING_BOARD,
    );
    const boardIds = allMocBoards.map((obj) => obj.id);
    const validKeys = boardIds.filter((key) => key in deepCloneBoardMocDetails);
    values.boardMocDetailsSelection = validKeys;
    values.boardMocDetails = Object.fromEntries(validKeys.map((key) => [key, deepCloneBoardMocDetails[key]]));
  };

  const handleAdvanceForm = useCallback(
    async (values: AddActivityFormValues): Promise<void> => {
      if (activeStep.label === 'Information for Learners') {
        if (
          values.isMoc === 'isMoc-true' &&
          values.credits?.[amaTerm.id] !== undefined &&
          values.boardMocDetailsSelection?.length
        ) {
          values.boardMocDetailsSelection?.forEach((selection: string) => {
            values.boardMocDetails = values.boardMocDetails || {};
            if (!values.boardMocDetails[selection]) {
              values.boardMocDetails[selection] = {};
            }
            // `mocPointsGiven` rules:
            // - If any IBCTCreditType for this IBCTBoard has `mustMatchCmeAmount` === true, always match the `amaCreditValue`
            // - Otherwise always take the lowest between `amaCreditValue` and `mocCreditValue`
            // - If this IBCTBoard was just added on Step 2 of the form, default the `mocCreditValue` to `amaCreditValue`
            const config: IBCTBoard = configuredBoardsDictionary?.[selection];
            const creditTypes: IBCTCreditType[] = config?.creditTypes;
            const isMatchAmaCreditValue: boolean = creditTypes?.some(({ mustMatchCmeAmount }) => mustMatchCmeAmount);
            const amaCreditValue: number = values?.credits?.[amaTerm?.id] as number;
            const mocCreditValue: number = values?.boardMocDetails?.[selection]?.mocPointsGiven || amaCreditValue;
            // Per ACCME, reset points to match AMA after AMA is updated IF new AMA value is less than mocPointsGiven
            values.boardMocDetails[selection].mocPointsGiven = isMatchAmaCreditValue
              ? amaCreditValue
              : min([amaCreditValue, mocCreditValue]) || 0;
            // If the config is available and any credit types are DEFAULT and missing
            // add them to the typesOfCreditIds array for that board
            if (configuredBoardsDictionary[selection]) {
              const selectedCreditTypes: string[] = values.boardMocDetails[selection].typesOfCreditIds || [];
              const boardDefaultCreditId: string = configuredBoardsDictionary[selection]?.creditTypes?.find(
                ({ default: isDefault }) => isDefault,
              )?.id;
              if (boardDefaultCreditId && !selectedCreditTypes?.includes(boardDefaultCreditId)) {
                values.boardMocDetails[selection].typesOfCreditIds = [...selectedCreditTypes, boardDefaultCreditId];
              }
            }
          });
          // Pick to remove any soft-deleted boards from the overall model
          values.boardMocDetails = pick(values.boardMocDetails, values.boardMocDetailsSelection);
        }
        if (typeof values.supportedRemsIds === 'string') {
          values.supportedRemsIds = [values.supportedRemsIds];
        }
        if (values.isRems === 'isRems-true' && values.supportedRemsIds?.length) {
          values.supportedRemsIds?.forEach((selection: string) => {
            values.boardRemsDetails = values.boardRemsDetails || {};
            if (!values.boardRemsDetails[selection]) {
              values.boardRemsDetails[selection] = {};
            }
          });
          // Pick to remove any soft-deleted REMS from the overall model
          values.boardRemsDetails = pick(values.boardRemsDetails, values.supportedRemsIds);
        }
      }

      if (cpactparams && activeStep.label !== 'Basics' && activeStep.label !== 'REMS') {
        await filterMocValues(values);
      }
      // Filter blank string and undefined from Pharmacy live date
      if (values.pharmacyLiveDates?.length > 0) {
        values.pharmacyLiveDates = values.pharmacyLiveDates.filter((x) => !!x);
      }
      // API call to submit the form.
      await handleAdd(values);
    },
    [activeStep, amaTerm, configuredBoardsDictionary, dispatch, handleAdd],
  );
  // initial values for copied object
  const processedCopyDefault = () => {
    const cparms = cpactparams && cpactparams.params;
    const dateLoc: IDatesAndLocation = {
      city: '',
      country: '',
      endDate: null,
      postalCode: '',
      startDate: null,
      stateOrProvince: '',
    };
    const tdl: IDatesAndLocation[] = [dateLoc];

    return cpactparams && cpactparams.params
      ? {
          tempDatesAndLocation: tdl,
          startDate: null,
          endDate: null,
          dateLoc,
          commendationCriteriaIds: [],
          description: cparms.description ?? '',
          detailsUrl: cparms.detailsUrl ?? '',
          hasCommercialSupport: cparms.hasCommercialSupport
            ? 'hasCommercialSupport-true'
            : 'hasCommercialSupport-false',
          includeInCmeFinder: cparms.includeInCmeFinder ? 'includeInCmeFinder-true' : 'includeInCmeFinder-false',
          isTargetAudiencePharmacy: isJa ? 'isTargetAudiencePharmacy' : '',
          isTargetAudiencePharmacyTech: isJa ? 'isTargetAudiencePharmacyTech' : '',
          internalId: '',
          locationType: cparms.locationType !== undefined ? cparms.locationType : [],
          markedForCommendationCriteria: 'markedForCommendationCriteria-false',
          remsRpcId: '',
          title: cparms.title ?? '',
          typeId: cparms.typeId ?? '',
          participationFee: cparms.participationFee ?? '',
          boardMocDetails: cparms.boardMocDetails ?? {},
          commercialSupportSources: cparms.commercialSupportSources ?? [],
          credits: {},
          isIpce: isJa ? (cparms.isIpce ? 'isIpce-true' : 'isIpce-false') : '',
          isJointlyProvided:
            cparms.isJointlyProvided === undefined ? '' : cparms.isJointlyProvided ? 'joint' : 'direct',
          isRemsCompliant: 'isRemsCompliant-false',
          isPharmacyCertificateProgram: isJa
            ? cparms?.isPharmacyCertificateProgram
              ? 'isPharmacyCertificateProgram-true'
              : 'isPharmacyCertificateProgram-false'
            : '',
          isRestrictedAudience:
            cparms?.isRestrictedAudience === true ? 'limited' : cparms?.isRestrictedAudience === false ? 'open' : '',
          targetAudience: isJa ? cparms.targetAudience ?? [] : [],
          jointProviders: [],
          mocProgramAttestation: [],
          supportedRemsIds: keys(cparms.boardRemsDetails) ?? [],
          selectedRemsBoardIds: keys(cparms.boardRemsDetails) ?? [],
          id: '',
          hasIndividualLearners: false,
          isDeleted: false,
          isRems: cparms.isRems ? 'isRems-true' : 'isRems-false',
          isMoc: cparms.isMoc ? 'isMoc-true' : 'isMoc-false',
          isMips: 'isMips-false',
          commercialSupportSourceKind: cparms.commercialSupportSourceKind ?? [],
          pharmacyLiveDates: [],
          recurring: 'recurring-false',
          participationFeeTypeId: cparms.participationFeeTypeId ?? '',
          type: cparms.type ?? '',
          measuredOutcomes: [],
          outcomesMeasured: cparms.outcomesMeasured,
          pharmacyTopicId: isJa ? cparms.pharmacyTopicId : '',
          practiceAreas: [],
          boards: [],
          remsDetails: [],
          isRestrictedAudienceGroup: cparms.isRestrictedAudienceGroup ?? '',
          isREMSRepeater: false,
          isMOCBoardRepeater: false,
          RemsRepeater: true,
          providership: '',
          boardMocDetailsSelection: keys(cparms.boardMocDetails) ?? [],
          selectedSpecialtyBoardIds: keys(cparms.boardMocDetails) ?? [],
          boardRemsDetails: cparms.boardRemsDetails ?? {},
          mocPointsGiven: cparms.mocPointsGiven,
          pharmacyQuestions: [],
          pharmacySequenceNumber: undefined,
          learnerCounts: {},
          isIndividualActivity: cparms.isIndividualActivity,
          iaOrganizationName: cparms.iaOrganizationName,
        }
      : null;
  };

  const handleCollaborationSteps = useCallback(
    (isMoc, isRems, hasContentTag) => {
      const updatedFormSteps = [...addActivityFormSteps];
      const mocIndex = addActivityFormSteps.findIndex((step) => step.label === 'MOC');
      const remsIndex = addActivityFormSteps.findIndex((step) => step.label === 'REMS');
      const contentTagIndex = addActivityFormSteps.findIndex((step) => step.label === 'State Content Tagging');
      const collaborationIndex = addActivityFormSteps.findIndex((step) => step.label === 'Collaborations');

      if (mocIndex >= 0) {
        updatedFormSteps[mocIndex].visible = isMoc?.indexOf('true') >= 0;
      }
      if (remsIndex >= 0) {
        updatedFormSteps[remsIndex].visible = isRems?.indexOf('true') >= 0;
      }
      if (contentTagIndex >= 0) {
        updatedFormSteps[contentTagIndex].visible = hasContentTag > 0;
      }

      if (collaborationIndex >= 0) {
        updatedFormSteps[collaborationIndex].visible = !(isMoc?.indexOf('true') >= 0 || isRems?.indexOf('true') >= 0);
      }
      setAddActivityFormSteps(updatedFormSteps);
    },
    [addActivityFormSteps],
  );

  useEffect(() => {
    if (!activityTypes.length) {
      dispatchGetActivityTypes();
    }
  }, [activityTypes, dispatchGetActivityTypes]);

  useEffect(() => {
    if (!addActivityFormSteps.length) {
      setAddActivityFormSteps([
        {
          label: 'Basics',
          stepStatus: 'active',
          visible: true,
        },
        {
          label: 'Information for Learners',
          visible: true,
        },
        {
          label: 'Accreditation Details',
          visible: true,
        },
        {
          label: 'State Content Tagging',
          visible: false,
        },
        {
          label: 'Collaborations',
          stepStatus: 'disabled',
          visible: true,
        },
        {
          label: 'MOC',
          visible: false,
        },
        {
          label: 'REMS',
          visible: false,
        },
        {
          label: 'Complete',
          visible: true,
        },
      ]);
    }
    setActiveStep(findActiveStep());
  }, [dispatchGetActivityTypes, addActivityFormSteps, activeStep]);

  const isLoading: boolean = useMemo(() => activityTypes.length < 1, [activityTypes]);

  useEffect(() => {
    if (!isLoading) {
      fireLoadEvent({ AddActivityStep: stepNumber });
    }
  }, [fireLoadEvent, isLoading, stepNumber]);

  return (
    <>
      <section className="form-container">
        {isLoading ? (
          <div className="form-group active">
            <div className={styles['loading-wrapper']}>
              <CircularProgress color="inherit" />
            </div>
          </div>
        ) : (
          <>
            <AddActivityForm
              activity={currentActivities}
              activeStep={activeStep}
              decrementStep={decrementStep}
              formSteps={addActivityFormSteps}
              goToStep={goToStep}
              dictionaries={credDicts}
              copiedActParams={(cpactparams && cpactparams.params) ?? null}
              // TODO currently we only have one current activity but after we connect repeater to date we need to revisit this [0]
              isDraft={currentActivities[0] ? currentActivities[0]?.status === StatusEnum.DRAFT : true}
              isFirstStep={determineIsStepPosition({ positionAtEnd: 0, step: activeStep })}
              isLastStep={determineIsStepPosition({ positionAtEnd: 1, step: activeStep })}
              isSubmitStep={determineIsStepPosition({ positionAtEnd: 2, step: activeStep })}
              onSubmit={handleAdvanceForm}
              updateCollaborationSteps={handleCollaborationSteps}
              validationSchemas={validationSchemas}
              processedInitValues={processedValues}
              initialValues={processedCopyDefault() ?? null}
            />
          </>
        )}
      </section>
      <aside className={classNames('rail-container rail-container--75', { open: isOpen })}>
        <Faq
          faqTitle={faqTitle}
          faqs={faqs}
          faqArea={faqArea}
          rollupOrganizationEnum={rollupOrganizationEnum}
          handleClose={() => dispatch(closeRail())}
        />
      </aside>
    </>
  );
};

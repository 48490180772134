import { ChangeEvent, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { cloneDeep, get, isNumber, keys, lowerFirst } from 'lodash';
import { Field, FormikProps, withFormik } from 'formik';
import { KeyboardBackspaceRounded, WarningRounded } from '@material-ui/icons';
import { Box, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { AnySchema } from 'yup';
import { ThunkAction } from 'redux-thunk';

// Components
import InputBlock from 'components/global/inputBlock/inputBlock';
import Button from 'components/Button/Button';
import { Modal } from 'components/ContinuousImprovement';
import Faq from 'components/Faq/Faq';
import { LocationDetailsRepeater } from 'components/LocationDetailsRepeater';
import { HighlightBox } from 'components/HighlightBox';

// Store + Core
import { FormService } from 'services/FormService';
import { organizationKindSelector, rollupOrganizationEnumSelector } from 'store/user/selectors';
import { ACTIVITY_TYPE_ABBREVIATION_ENUM, TAXONOMY_FAQ_ROOT_ID } from 'core/constants';
import {
  Activity,
  ActivityFormStateValues,
  ActivityType,
  BoardMocDetails,
  CommercialSupportSource,
  ContentTag,
  HasPharmacyContentTags,
  HasPharmacyRecertifications,
  HasStateContentTags,
  IBoardRemsDetails,
  IBoardSlim,
  IDictionary,
  IDisabledContext,
  IDisabledState,
  IInKindSupportSourceOption,
  IMonetarySupportSourceOption,
  ITaxonomyTerm,
  IUserOrganization,
  PARSAction,
  RollupOrganizationEnums,
  HasPharmRecertTemplateOptions,
  HasPharmContentTagTemplateOptions,
} from 'core/models';
import { ButtonVariant } from 'core/enums';
import { defaultAddFormProps } from '../constants';
import { closeFaq } from 'store/faq/actions';
import { isFaqOpenSelector } from 'store/faq/selectors';
import {
  amaCreditTermIdSelector,
  ipceCreditTermIdSelector,
  pharmacyCreditTermIdSelector,
} from 'store/taxonomy/selectors';
import { boardSelector, configuredBoardsDictionarySelector, configuredBoardsSelector } from 'store/board/selectors';
import { getAllBoards, getBoardById } from 'store/board/actions';
import { activitySelector, activityTypesSelector } from 'store/activity/selectors';
import { FormikCheckboxField } from '../../../../components/ContinuousImprovement/PureFormElements';

// Misc
import { getAllowedBoardSelections } from '../utils';

// Types
import { BoardTypes, IBCTBoard } from 'layouts/pages/bct/types';

// Hooks
import { useActivityTemplate, useFilterHook } from '../forms/hooks';

// Styles
import styles from './index.module.scss';

// Context
import { DisabledContext } from 'components/forms/DisabledContext';
import { configuredContentTagsHierarchySelector } from 'store/contentTags/selectors';
import { getAllContentTags } from 'store/contentTags/actions';
import { popToast } from 'store/toast/actions';
import { errorStickyToastOptions } from 'store/toast/constants';
import { AnalyticsService } from 'services/AnalyticsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import InputBlockWithErrorTouchCheck from '../../../../components/global/inputBlock/inputBlockWithErrorTouchCheck';

interface IEditActivityProps {
  activity: Activity;
  boards: IBoardSlim[];
  dictionaries: IDictionary<IDictionary<ITaxonomyTerm>>;
  editIndex: number;
  handleSubmit: (values: ActivityFormStateValues) => Promise<void>;
  idParam: string;
  types: ActivityType[];
  terms: IDictionary<ITaxonomyTerm>;
  schemas: AnySchema[];
}

const EditActivityFormik = (props: IEditActivityProps & FormikProps<ActivityFormStateValues>): ReactElement => {
  const {
    activity,
    boards,
    editIndex,
    errors,
    handleChange,
    handleSubmit,
    idParam,
    initialValues,
    isSubmitting,
    isValid,
    setFieldValue,
    submitForm,
    terms,
    touched,
    types,
    values,
  } = props;
  const disabledContextShape: IDisabledContext = useContext<IDisabledContext>(DisabledContext);
  const [generatedFields, setGeneratedFields] = useState<ReactElement[]>();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const rollupOrganizationEnum = useSelector(rollupOrganizationEnumSelector);
  const isJa = rollupOrganizationEnum === RollupOrganizationEnums.JA;
  const isNars = rollupOrganizationEnum === RollupOrganizationEnums.NARS;
  const isFaqOpen: boolean = useSelector(isFaqOpenSelector);
  const activityTypes = useSelector(activityTypesSelector);
  const amaCreditTerm: ITaxonomyTerm = useSelector(amaCreditTermIdSelector);
  const allBoards: IBoardSlim[] = useSelector(boardSelector);
  const contentTagsDetails: ContentTag[] = useSelector(configuredContentTagsHierarchySelector);
  const configurationDictionary: IDictionary<IBCTBoard> = useSelector(configuredBoardsDictionarySelector);
  const configuredBoards: IBCTBoard[] = useSelector(configuredBoardsSelector);
  const ipceCreditId = useSelector(ipceCreditTermIdSelector)?.id;
  const pharmacyCreditsTermId = useSelector(pharmacyCreditTermIdSelector)?.id;
  const nextSequenceNumber: number = useSelector(activitySelector)?.nextSequenceNumber;
  const amaCreditId = amaCreditTerm?.id;
  const activityTitle = activity?.title;
  const activityTypeId = activity?.typeId;
  const initialMocBoardIds: string[] = keys(activity?.boardMocDetails);
  const initialRemsBoardIds: string[] = keys(activity?.boardRemsDetails);
  const currentUserOrganization: IUserOrganization = useSelector(organizationKindSelector);
  const isPharmacyRecert = values.hasPharmacyRecertifications?.endsWith('1');

  // 4386 - depending on activity type and the currently selected boards, some boards need to be filtered
  const allowedBoardSelections: IBoardSlim[] = getAllowedBoardSelections(boards, activityTypeId, [
    ...initialMocBoardIds,
    ...initialRemsBoardIds,
  ]);
  const allMocBoards: IBoardSlim[] = allowedBoardSelections?.filter(
    ({ type }: IBoardSlim): boolean => type === BoardTypes.CERTIFYING_BOARD,
  );
  const allRemsBoards: IBoardSlim[] = allowedBoardSelections?.filter(
    ({ type }: IBoardSlim): boolean => type === BoardTypes.REMS,
  );

  // Separate boards by board type
  const configuredMocBoards: IBCTBoard[] = useFilterHook(BoardTypes.CERTIFYING_BOARD, configuredBoards);
  const configuredRemsBoards: IBCTBoard[] = useFilterHook(BoardTypes.REMS, configuredBoards);

  const isPharmacyCreditsChecked: boolean = (values?.credits?.[pharmacyCreditsTermId] as number) > 0;
  const isPharmacyAbbreviationL: boolean =
    activityTypes?.find((type: ActivityType): boolean => type.id === values?.typeId)?.pharmacyAbbreviation === 'L';

  const activityType = activityTypes?.find(({ id }: ActivityType) => id === values?.typeId);
  const doesAMACreditHaveValue = !!values?.credits?.[amaCreditId];
  const doesIPCECreditHaveValue = !!values?.credits?.[ipceCreditId];
  const allContentTags = useMemo(
    () =>
      Object.values(contentTagsDetails)
        .flat()
        .map((tag) => tag),
    [contentTagsDetails],
  );

  const pharmacyQuestionFields = [
    'targetAudience',
    'pharmacyTypeId',
    'isPharmacyCertificateProgram',
    'pharmacyTopicId',
    'pharmacyLiveDates',
    'hasPharmacyRecertifications',
    'hasPharmacyContentTags',
  ];

  const cmePassportQuestionFields = ['detailsUrl', 'isRestrictedAudience', 'participationFeeTypeId'];

  const setActiveFieldClass = () => {
    // The following cannot be done via the template because it comes from taxonomy.
    // Hide/Show these group when the page renders.
    if (doesAMACreditHaveValue || (isJa && doesIPCECreditHaveValue)) {
      document.querySelector('[data-group-id="includeInCmeFinderGroup"]')?.classList.remove('hidden');
      document.querySelector('[data-group-id="isRestrictedAudienceGroup"]')?.classList.remove('hidden');
      document.querySelector('[data-group-id="detailsUrlGroup"]')?.classList.remove('hidden');
      // JA is hasStateContentTags, PARS is HasStateContentTags - just operate on both
      document.querySelector('[data-group-id="hasStateContentTags"]')?.classList.remove('hidden');
      document.querySelector('[data-group-id="HasStateContentTags"]')?.classList.remove('hidden');
    } else if (isNars) {
      // For NARS the includeInCmeFinderGroup should be visible but not the top level includeInCmeFinder input.
      document.querySelector('[data-group-id="includeInCmeFinderGroup"]')?.classList.remove('hidden');
      document.querySelector('[data-group-id="hasStateContentTags"]')?.classList.add('hidden');
      document.querySelector('[data-group-id="HasStateContentTags"]')?.classList.add('hidden');
    } else {
      document.querySelector('[data-group-id="includeInCmeFinderGroup"]')?.classList.add('hidden');
      document.querySelector('[data-group-id="isRestrictedAudienceGroup"]')?.classList.add('hidden');
      document.querySelector('[data-group-id="detailsUrlGroup"]')?.classList.add('hidden');
      // JA is hasStateContentTags, PARS is HasStateContentTags - just operate on both
      document.querySelector('[data-group-id="hasStateContentTags"]')?.classList.add('hidden');
      document.querySelector('[data-group-id="HasStateContentTags"]')?.classList.add('hidden');
    }

    if (isPharmacyCreditsChecked) {
      document.querySelector('[data-group-id="pharmacyQuestions"]')?.classList.remove('hidden');
      document.querySelector('[data-sub-group-id="hasPharmacyRecertifications"]')?.classList.remove('hidden');
      document.querySelector('[data-sub-group-id="hasPharmacyContentTags"]')?.classList.remove('hidden');
      if (isPharmacyAbbreviationL) {
        document.querySelector('[data-question="pharmacyLiveDates"]')?.parentElement?.classList.remove('hidden');
      } else {
        document.querySelector('[data-question="pharmacyLiveDates"]')?.parentElement?.classList.add('hidden');
      }

      if (!isPharmacyRecert) {
        document.querySelector('[data-sub-group-id="pharmacyRecertificationTaxonomyTerms"]')?.classList.add('hidden');
      } else {
        document
          .querySelector('[data-sub-group-id="pharmacyRecertificationTaxonomyTerms"]')
          ?.classList.remove('hidden');
      }
    } else {
      document.querySelector('[data-group-id="pharmacyQuestions"]')?.classList.add('hidden');
      document.querySelector('[data-sub-group-id="hasPharmacyRecertifications"]')?.classList.add('hidden');
      document.querySelector('[data-sub-group-id="hasPharmacyContentTags"]')?.classList.add('hidden');
    }
  };

  // The setActiveFieldClass method triggers on every formik state change unless it is in a useEffect hook.
  // A useRef hook cannot be used here since the form is using a json template.
  useEffect(() => {
    setActiveFieldClass();
  }, []);
  // like the add form, we need this to trigger on each render to make sure the fields are hidden/shown immediately when a value changes
  setActiveFieldClass();

  // Show the pharmacy questions when the pharmacy checkbox is checked.
  // Cannot be done via the template because it comes from taxonomy.
  useEffect(() => {
    if (isPharmacyCreditsChecked) {
      document.querySelector('[data-group-id="pharmacyQuestions"]')?.classList.remove('hidden');
      // If is-recurring was selected, don't show a number input, show a message instead.
      if (values.recurring === 'recurring-true') {
        const node = document.querySelector('[data-question="pharmacySequenceNumber"]');
        if (node) {
          const message = document.createElement('div');
          message.setAttribute('class', 'caption-text form-input');
          message.innerHTML =
            'For recurring activities, the sequence number for the UAN will be autogenerated for each activity instance.';
          node.parentNode.replaceChild(message, node);
        }
      }
    } else {
      document.querySelector('[data-group-id="pharmacyQuestions"]')?.classList.add('hidden');
    }
  }, [isPharmacyCreditsChecked, values?.credits, values?.recurring]);

  useEffect(() => {
    if (!allContentTags?.length) {
      dispatch(getAllContentTags());
    }
  }, [allContentTags, dispatch]);

  // Since the Pharmacy Questions' visibility is custom, we have to rely on this hook to reset fields on hide
  useEffect(() => {
    if (!isPharmacyCreditsChecked && initialValues) {
      pharmacyQuestionFields.forEach((fieldKey) => setFieldValue(fieldKey, initialValues?.[fieldKey]));
    }
  }, [isPharmacyCreditsChecked]);

  useEffect(() => {
    checkCreditsForCmePassport();
  }, [doesAMACreditHaveValue, isJa, doesIPCECreditHaveValue]);

  /**
   * 4793: Since the CME Passport Questions' visibility is custom, we have to rely on this hook to reset fields on hide.
   *
   * 5909: The CME passport selection needs to stay at it's initial value when editing an activity
   * unless both required JA credits do not have a value.
   *
   * If the activity has initial values and it is JA then the required JA credits are checked.
   * If none of the required JA credits have a value then the question field's visibility is changed
   * and the include in CME passport input is set to false.
   *
   * If the activity has initial values and it is PARS then the required AMA credit is checked.
   * If AMA credits does not have a value then the question field's visibility is changed
   * and the include in CME passport input is set to false.
   *
   * @returns {boolean} Returns true if the user has enough credits, otherwise false.
   */
  const checkCreditsForCmePassport = (): void => {
    let result: boolean;
    if (initialValues) {
      if (isJa) {
        if (doesAMACreditHaveValue && !doesIPCECreditHaveValue) {
          result = false;
        } else if (!doesAMACreditHaveValue && doesIPCECreditHaveValue) {
          result = false;
        } else if (!doesAMACreditHaveValue && !doesIPCECreditHaveValue) {
          result = true;
        } else {
          result = false;
        }
      }

      if (!isJa && !isNars) {
        result = !doesAMACreditHaveValue;
      }
    }

    if (result) {
      cmePassportQuestionFields.forEach((fieldKey) => setFieldValue(fieldKey, initialValues?.[fieldKey]));
      setFieldValue('includeInCmeFinder', 'includeInCmeFinder-false');
    }
  };

  useEffect(() => {
    // NARS includeInCmeFinder should always be false.
    if (isNars) {
      setFieldValue('includeInCmeFinder', 'includeInCmeFinder-false');
    }
  }, [values.includeInCmeFinder]);

  const activityTemplate = useActivityTemplate();

  useEffect(() => {
    const hasActivity = !!activity;
    const hasTerms = !!terms;
    const hasBoards = boards && boards?.length;
    const hasModernBoards = allBoards && allBoards?.length;
    const hasTypes = types && types?.length;
    const hasValues = !!values;
    const selectedTemplate = activityTemplate?.[editIndex];
    const hasTemplateIndex = !!selectedTemplate;
    const shouldRegenerate =
      hasActivity && hasTerms && hasBoards && hasModernBoards && hasTypes && hasValues && hasTemplateIndex;
    if (shouldRegenerate) {
      setGeneratedFields(
        FormService.buildForm({
          activities: activity ? [activity] : null,
          allMocBoards,
          allRemsBoards,
          allContentTags,
          amaCreditValue: values?.credits?.[amaCreditTerm?.id] as number,
          configurationDictionary,
          configuredMocBoards,
          configuredRemsBoards,
          disabledContext: disabledContextShape,
          formMeta: [selectedTemplate],
          formikBag: {
            errors,
            handleChange,
            initialValues: defaultAddFormProps,
            setFieldValue,
            touched,
            values,
          },
          rollupOrganizationEnum,
          selectedRemsBoardIds: values?.supportedRemsIds,
          selectedSpecialtyBoardIds: values?.boardMocDetailsSelection,
          terms,
        }),
      );
    }
  }, [
    activity,
    activityTemplate,
    allBoards,
    boards,
    errors,
    terms,
    touched,
    types,
    values?.commercialSupportSourceKind,
    values?.hasCommercialSupport,
    values?.isJointlyProvided,
    values?.isMoc,
    values?.isRems,
    values.hasPharmacyContentTags,
    values.hasPharmacyRecertifications,
    values.hasStateContentTags,
    values?.markedForCommendationCriteria,
    values?.recurring,
    values?.typeId,
    nextSequenceNumber,
  ]);

  useEffect(() => {
    const activityMocIds = keys(activity?.boardMocDetails);
    const activityRemsIds = keys(activity?.boardRemsDetails);
    const missingConfigIds = [...activityMocIds, ...activityRemsIds]?.filter((id) => !configurationDictionary?.[id]);
    const metadataPromises: ThunkAction<Promise<void>, unknown, null, PARSAction<unknown>>[] = missingConfigIds?.length
      ? missingConfigIds?.map((id) => dispatch(getBoardById(id)))
      : [];

    Promise.all(metadataPromises);
  }, [activity?.boardMocDetails, activity?.boardRemsDetails]);

  //  Control for state tagging
  useEffect(() => {
    if (isPharmacyCreditsChecked) {
      const { targetAudience } = values;
      const isTargetAudiencePharmacy = targetAudience?.includes('isTargetAudiencePharmacy');
      const isTargetAudiencePharmacyTech = targetAudience?.includes('isTargetAudiencePharmacyTech');
      const bothAreTrue = isTargetAudiencePharmacy && isTargetAudiencePharmacyTech;

      const mappings = [
        {
          selector: '[data-sub-group-id="pharmacyRecertificationTaxonomyTerms"]',
          condition: isPharmacyRecert && (bothAreTrue || isTargetAudiencePharmacy || isTargetAudiencePharmacyTech),
        },
        {
          selector: '[id="Pharmacists"]',
          condition: isTargetAudiencePharmacy && isPharmacyRecert,
        },
        {
          selector: '[id="Pharmacists Prescriptive Authority"]',
          condition: isTargetAudiencePharmacy && isPharmacyRecert,
        },
        { selector: '[id="Pharmacy Technicians"]', condition: isTargetAudiencePharmacyTech && isPharmacyRecert },
      ];

      // Disables the 'Yes' option for 'hasPharmacyRecertifications' based on whether a target audience is selected.
      // Also makes sure that deselecting all target audiences resets the option back to 'No'.
      const recertElement = document.querySelector(`[id=${HasPharmRecertTemplateOptions.yesPharmCert1}]`);
      const recertRootElement = recertElement?.parentElement?.parentElement;
      const previousRecertValue = values.hasPharmacyRecertifications;
      if (bothAreTrue || isTargetAudiencePharmacy || isTargetAudiencePharmacyTech) {
        recertElement?.parentElement?.classList.remove('disabled-radio-button');
        recertRootElement?.classList.remove('disabled-radio-button');
      } else {
        if (previousRecertValue !== HasPharmRecertTemplateOptions.notSurePharmCert2) {
          setFieldValue('hasPharmacyRecertifications', HasPharmRecertTemplateOptions.noPharmCert0);
        }
        recertElement?.parentElement?.classList.add('disabled-radio-button');
        recertRootElement?.classList.add('disabled-radio-button');
      }

      // Disables the 'Yes' option for 'hasPharmacyContentTags' based on whether a target audience is selected.
      // Also makes sure that deselecting all target audiences resets the option back to 'No'.
      const contentTagElement = document.querySelector(`[id=${HasPharmContentTagTemplateOptions.yesPharmContentTag1}]`);
      const contentTagRootElement = contentTagElement?.parentElement?.parentElement;
      const previousContentTagValue = values.hasPharmacyContentTags;
      if (bothAreTrue || isTargetAudiencePharmacy || isTargetAudiencePharmacyTech) {
        contentTagElement?.parentElement?.classList.remove('disabled-radio-button');
        contentTagRootElement?.classList.remove('disabled-radio-button');
      } else {
        if (previousContentTagValue !== HasPharmContentTagTemplateOptions.notSurePharmContentTag2) {
          setFieldValue('hasPharmacyContentTags', HasPharmContentTagTemplateOptions.noPharmContentTag0);
        }
        contentTagElement?.parentElement?.classList.add('disabled-radio-button');
        contentTagRootElement?.classList.add('disabled-radio-button');
      }

      /**
       * Retrieves taxonomy term list elements from DOM and modifies the corresponding element's visibility.
       * @param {Array} mappings - An array of mappings containing a selector and condition for each mapping.
       * @returns {Promise} - A Promise that resolves once each element's visibility is modified.
       */
      const getTaxonomyTermsFromMappings = async (mappings: { selector: string; condition: any }[]): Promise<void> => {
        for (const { selector, condition } of mappings) {
          const [element] = await Promise.all([document.querySelector(selector)]);
          if (condition) {
            element?.classList.remove('hidden');
          } else {
            element?.classList.add('hidden');
          }
        }
      };
      getTaxonomyTermsFromMappings(mappings).then();

      if (!values.hasPharmacyRecertifications?.endsWith('1')) {
        values.pharmacyRecertificationTaxonomyTerms = [];
      }
      if (!targetAudience?.length) {
        setFieldValue('pharmacyRecertificationTaxonomyTerms', []);
      }
    } else {
      setFieldValue('hasPharmacyContentTags', 'noPharmContentTag0');
      setFieldValue('pharmacyRecertificationTaxonomyTerms', []);
      setFieldValue('hasPharmacyRecertifications', HasPharmRecertTemplateOptions.noPharmCert0);
    }
  }, [isPharmacyCreditsChecked, values.credits, values.targetAudience, values.hasPharmacyRecertifications]);

  // Default to next available sequence number if one was provided via API
  // Only add to the model if the pharmacy credits are checked
  // Remove from the model when pharmacy credits is unchecked
  useEffect(() => {
    const isSequenceNumberValid = isNumber(values?.pharmacySequenceNumber);
    const isPharmacyCreditsChecked = !!values?.credits?.[`credits${pharmacyCreditsTermId}`];
    if (!isSequenceNumberValid && !!isPharmacyCreditsChecked) {
      setFieldValue('pharmacySequenceNumber', nextSequenceNumber);
    }
    if (isSequenceNumberValid && !isPharmacyCreditsChecked) {
      setFieldValue('pharmacySequenceNumber', undefined);
    }
  }, [nextSequenceNumber, values?.credits]);

  useEffect(() => {
    const isBoardConfigAvailable = !!allBoards?.length;
    if (!isBoardConfigAvailable) {
      dispatch(getAllBoards());
    }
  }, [allBoards, dispatch]);

  const shouldShowLocationInformation =
    !!values?.locationType?.includes('InPerson') &&
    (activityType?.abbreviation?.includes(ACTIVITY_TYPE_ABBREVIATION_ENUM.C) ||
      activityType?.abbreviation?.includes(ACTIVITY_TYPE_ABBREVIATION_ENUM.RSS));
  const shouldShowRepeater = false; // Never show the repeater controls on the Edit screen

  const faqs: ITaxonomyTerm[] = (props.terms[TAXONOMY_FAQ_ROOT_ID] || {})?.terms || [];

  let faqArea = '';
  let faqTitle = '';

  switch (editIndex) {
    case 0:
      faqArea = 'Basics';
      faqTitle = 'Basics FAQs';
      break;
    case 1:
      faqArea = 'Learners';
      faqTitle = 'Information for Learners FAQs';
      break;
    case 2:
      faqArea = 'Accreditation';
      faqTitle = 'Accreditation Details FAQs';
      break;
    case 3:
      faqArea = 'State Content Tagging';
      faqTitle = 'State Content Tagging FAQs';
      break;
    case 4:
      faqArea = 'MOC';
      faqTitle = 'MOC FAQs';
      if (isNars) {
        faqArea = 'REMS';
        faqTitle = 'REMS FAQs';
      }
      break;
    case 5:
      faqArea = 'REMS';
      faqTitle = 'REMS FAQs';
      break;
  }

  const onClickCancelWithoutSaving = () => {
    setIsConfirmDialogOpen(true);
  };

  const closeFaqSidebar = () => {
    dispatch(closeFaq());
  };

  const onCloseAndNavigateAway = () => {
    setIsConfirmDialogOpen(false);
    history.push(`/activities/detail/${idParam}`);
  };

  const onCloseAndSave = async () => {
    setIsConfirmDialogOpen(false);
    await submitForm();
  };

  const handleSave = async () => {
    await submitForm();
  };

  const typeIdDisabledState: IDisabledState = get(disabledContextShape, 'typeId') as IDisabledState;
  if (activityTemplate?.length > 0 && !activityTemplate[editIndex]) {
    // activity template was loaded, but we got no fields for this section - something is wrong.
    const message = `Unable to edit activity section ${editIndex}`;
    AnalyticsService.trackException({
      exception: new Error(message),
      properties: {
        activityId: activity.id,
        activityTemplateLength: activityTemplate?.length,
        editIndex,
        generatedFieldsLength: generatedFields?.length,
        isJa,
        isNars,
      },
      severityLevel: SeverityLevel.Error,
    });
    dispatch(
      popToast({
        ...errorStickyToastOptions,
        message: <>{message}</>,
      }),
    );
    return <Redirect to={`/activities/detail/${activity.id}`} />;
  }

  return (
    <>
      <section className={`form-container ${styles['edit-activity-container']}`}>
        <div className={classNames('form-title-container', styles['form-title-container'])}>
          <div className="eyebrow">Update an activity</div>
          <HighlightBox variant="warning">
            <Box display="flex" component="p">
              <WarningRounded />
              <Box ml={2}>Updates can only be made to editable fields.</Box>
            </Box>
          </HighlightBox>
          <h4>{activityTitle}</h4>
        </div>
        <form onSubmit={handleSubmit} className="static-form">
          {editIndex === 0 && (
            <>
              {isNars && currentUserOrganization.isApproverOfActivities && (
                <div data-template-id="Basics" className="form-group">
                  <InputBlock
                    errors={errors}
                    name="isIndividualActivity"
                    suppressLabel
                    title="Is this an Individual Activity?"
                    touched={touched}
                  >
                    <div className={styles.checkbox}>
                      <FormikCheckboxField
                        formikKey="isIndividualActivity"
                        name="Yes"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('isIndividualActivity', e.target.checked)
                        }
                        checked={values.isIndividualActivity}
                      />
                    </div>
                  </InputBlock>
                  {values.isIndividualActivity && (
                    <InputBlockWithErrorTouchCheck
                      errors={errors}
                      name="iaOrganizationName"
                      required
                      suppressLabel
                      title="What's the name of the individual activity organization?"
                      touched={touched}
                    >
                      <Field
                        aria-label="What's the name of the individual activity organization?"
                        id="iaOrganizationName"
                        name="iaOrganizationName"
                        placeholder="Enter Individual Activity Organization Name"
                        type="text"
                      />
                    </InputBlockWithErrorTouchCheck>
                  )}
                </div>
              )}
              <div data-template-id="Basics" className="form-group">
                <InputBlockWithErrorTouchCheck
                  required
                  suppressLabel
                  errors={errors}
                  touched={touched}
                  name="title"
                  title="What's the name of the activity"
                >
                  <Field
                    aria-label="Activity Name"
                    id="title"
                    name="title"
                    placeholder="Enter Activity Name"
                    type="text"
                    aria-invalid={!!errors?.title}
                  />
                </InputBlockWithErrorTouchCheck>
              </div>
              <div data-template-id="Basics" className="form-group">
                <InputBlockWithErrorTouchCheck
                  ariaLabel="Activity Format"
                  required
                  suppressLabel
                  errors={errors}
                  touched={touched}
                  name="typeId"
                  role="radiogroup"
                  title="What is the activity format?"
                >
                  {types.map(({ id, title, description }: ActivityType) => (
                    <label htmlFor={id} key={id} className="form-input-radio">
                      <Field
                        aria-invalid={!!errors?.typeId?.length}
                        disabled={typeIdDisabledState?.isDisabled && typeIdDisabledState?.disabledOptions?.includes(id)}
                        id={id}
                        name="typeId"
                        type="radio"
                        value={id}
                      />
                      <span className="checkmark">
                        <div className="label-text-container">
                          <div className="label-text">{title}</div>
                          <div className="caption-text">{description}</div>
                        </div>
                      </span>
                    </label>
                  ))}
                </InputBlockWithErrorTouchCheck>
              </div>
            </>
          )}
          {generatedFields}
          {editIndex === 0 && (
            <div data-template-id="Basics" className="form-group">
              <LocationDetailsRepeater
                isDisabled={false}
                formikKey="tempDatesAndLocation"
                shouldShowLocationInformation={shouldShowLocationInformation}
                shouldShowRepeater={shouldShowRepeater}
              />
            </div>
          )}
          <div className={styles['button-row']}>
            <Button variant={ButtonVariant.Tertiary} onClick={onClickCancelWithoutSaving}>
              <KeyboardBackspaceRounded className="tertiary-icon-back" />
              Cancel Without Saving
            </Button>
            <Button
              disabled={!isValid || isSubmitting}
              onClick={handleSave}
              startIcon={isSubmitting && <CircularProgress color="inherit" size="1rem" />}
              variant={ButtonVariant.Primary}
            >
              Save
            </Button>
          </div>
        </form>
        <Modal
          isOpen={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          title="Do you want to save your changes before quitting?"
        >
          <div className={styles['button-row']}>
            <Button variant={ButtonVariant.Secondary} onClick={onCloseAndNavigateAway}>
              No, Quit Without Saving
            </Button>
            <Button disabled={!isValid || isSubmitting} variant={ButtonVariant.Primary} onClick={onCloseAndSave}>
              Yes, Save and Quit
            </Button>
          </div>
        </Modal>
      </section>
      <aside className={classNames('rail-container rail-container--75', { open: isFaqOpen })}>
        <Faq
          faqTitle={faqTitle}
          faqs={faqs}
          faqArea={faqArea}
          rollupOrganizationEnum={rollupOrganizationEnum}
          handleClose={closeFaqSidebar}
        />
      </aside>
    </>
  );
};

const EditActivityForm = withFormik<IEditActivityProps, ActivityFormStateValues>({
  enableReinitialize: true,
  handleSubmit: async (values, { props, setSubmitting, setStatus }) => {
    if (!values.isIndividualActivity) values.iaOrganizationName = null;

    const { handleSubmit } = props;
    setSubmitting(true);
    try {
      await handleSubmit(values);
    } catch (e) {
      setStatus(e);
    }
    setSubmitting(false);
  },
  mapPropsToValues: (props: IEditActivityProps): ActivityFormStateValues => {
    const { activity, dictionaries } = props;
    const supportSources = ['Monetary', 'NonMonetary'];
    if (activity && dictionaries) {
      const { creditTypesDictionary } = dictionaries;
      const {
        commercialSupportSources = [],
        jointProviders = [],
        boardMocDetails,
        boardRemsDetails,
        city,
        country,
        credits,
        endDate,
        postalCode,
        startDate,
        stateOrProvince,
        targetAudience,
        ...restActivity
      } = activity;

      const recreateMonetarySupportSourceOption = ({
        source,
        amountGiven,
      }: CommercialSupportSource): IMonetarySupportSourceOption => ({
        amountGiven,
        id: source,
        source: source,
      });
      const recreateInKindSupportSourceOption = ({ source }: CommercialSupportSource): IInKindSupportSourceOption =>
        source;
      // Split commercialSupportSources into In-Kind and Monetary
      const inKindSupportSources: CommercialSupportSource[] = commercialSupportSources.filter(
        ({ hasInKindSupport }): boolean => hasInKindSupport,
      );
      const monetarySupportSources: CommercialSupportSource[] = commercialSupportSources.filter(
        ({ hasInKindSupport, amountGiven }): boolean => hasInKindSupport === false || amountGiven !== undefined,
      );

      const commercialMonetarySupportSources: IMonetarySupportSourceOption[] = monetarySupportSources.map(
        recreateMonetarySupportSourceOption,
      );

      const commercialInKindSupportSources: IInKindSupportSourceOption[] = inKindSupportSources.map(
        recreateInKindSupportSourceOption,
      );

      const commercialSupportSourceKind: string[] = supportSources.filter((sourceKind): boolean => {
        if (sourceKind === 'Monetary') {
          return !!monetarySupportSources?.length;
        }
        if (sourceKind === 'NonMonetary') {
          return !!inKindSupportSources?.length;
        }
        return false;
      });

      const boardIds = keys(boardMocDetails);
      const remsIds = keys(boardRemsDetails);

      // Deep-clone to avoid reference equality in deep JS objects
      const deepCloneBoardMocDetails: IDictionary<BoardMocDetails> = cloneDeep(boardMocDetails);
      const deepCloneBoardRemsDetails: IDictionary<IBoardRemsDetails> = cloneDeep(boardRemsDetails);

      const targetAudienceList = targetAudience?.map((audience) => lowerFirst(audience)) || [];

      // Construct the selected checkboxes and initial values for the credits fields
      const allCreditTypeKeys: string[] = keys(creditTypesDictionary);
      const creditKeys: string[] = keys(credits);
      const creditsCheckboxes: IDictionary<boolean> = {};
      const creditsInitialValues: IDictionary<number> = {};

      creditKeys?.forEach((key: string): void => {
        creditTypesDictionary[key]
          ? (creditsCheckboxes[`credits${key}`] = true)
          : (creditsCheckboxes[`credits${key}`] = false);
      });

      allCreditTypeKeys?.forEach((key: string): void => {
        typeof credits[key] === 'number' ? (creditsInitialValues[key] = credits[key]) : (creditsInitialValues[key] = 0);
      });

      const dateAndLocationEntry = {
        city,
        country,
        endDate,
        postalCode,
        startDate,
        stateOrProvince,
      };
      const tempDatesAndLocation = [dateAndLocationEntry];

      // Possible values of activity.locationType
      // - 'In-Person'
      // - 'Live-Streamed'
      // - 'Online'
      // - 'Other'
      const transformedLocationType = activity?.locationType?.map((location) =>
        location?.replace(' ', '')?.replace('-', '')?.replace('S', 's'),
      );
      const getStateContentTagsValue = (ctsValue?: HasStateContentTags): string =>
        ctsValue === null || ctsValue === undefined
          ? null
          : ctsValue === HasStateContentTags.Yes
          ? 'stateContentTagYes1'
          : 'stateContentTagNotSure0';

      const getPharmacyRecertificationsValue = (phrValue?: HasPharmacyRecertifications): string =>
        phrValue === null || phrValue === undefined
          ? null
          : phrValue === HasPharmacyRecertifications.Yes
          ? HasPharmRecertTemplateOptions.yesPharmCert1
          : phrValue === HasPharmacyRecertifications.No
          ? HasPharmRecertTemplateOptions.noPharmCert0
          : HasPharmRecertTemplateOptions.notSurePharmCert2;

      const getPharmacyContentTagsValue = (phsValue?: HasPharmacyContentTags): string =>
        phsValue === null || phsValue === undefined
          ? null
          : phsValue === HasPharmacyContentTags.Yes
          ? 'yesPharmContentTag1'
          : phsValue === HasPharmacyContentTags.No
          ? 'noPharmContentTag0'
          : 'notSurePharmContentTag2';
      return {
        ...restActivity,
        boardMocDetails: deepCloneBoardMocDetails,
        boardMocDetailsSelection: boardIds,
        boardRemsDetails: deepCloneBoardRemsDetails,
        commercialInKindSupportSources,
        commercialMonetarySupportSources,
        commercialSupportSourceKind,
        contentTags: activity.contentTags,
        credits: {
          ...creditsInitialValues,
          ...creditsCheckboxes,
        },
        hasCommercialSupport: activity?.hasCommercialSupport
          ? 'hasCommercialSupport-true'
          : 'hasCommercialSupport-false',
        hasPharmacyContentTags: getPharmacyContentTagsValue(activity?.hasPharmacyContentTags),
        hasPharmacyRecertifications: getPharmacyRecertificationsValue(activity?.hasPharmacyRecertifications),
        hasStateContentTags: getStateContentTagsValue(activity?.hasStateContentTags),
        includeInCmeFinder: activity?.includeInCmeFinder ? 'includeInCmeFinder-true' : 'includeInCmeFinder-false',
        isIpce: activity?.isIpce ? 'isIpce-true' : 'isIpce-false',
        isJointlyProvided:
          activity?.isJointlyProvided === true ? 'joint' : activity?.isJointlyProvided === false ? 'direct' : '',
        isMips: activity?.isMips || activity?.isMips ? 'isMips-true' : 'isMips-false',
        isMoc: activity?.isMoc || activity?.isMoc ? 'isMoc-true' : 'isMoc-false',
        isPharmacyCertificateProgram: activity?.isPharmacyCertificateProgram
          ? 'isPharmacyCertificateProgram-true'
          : 'isPharmacyCertificateProgram-false',
        isRems: activity?.isRems || activity?.isRems ? 'isRems-true' : 'isRems-false',
        isRestrictedAudience:
          activity?.isRestrictedAudience === true ? 'limited' : activity?.isRestrictedAudience === false ? 'open' : '',
        jointProviders,
        locationType: transformedLocationType,
        markedForCommendationCriteria: activity?.markedForCommendationCriteria
          ? 'markedForCommendationCriteria-true'
          : 'markedForCommendationCriteria-false',
        mocProgramAttestation: activity?.mocProgramAttestation ? ['mocProgramAttestation'] : [],
        pharmacyContentTagStateTaxonomyTerms: activity?.pharmacyContentTagStateTaxonomyTerms,
        pharmacyContentTagTaxonomyTerms: activity?.pharmacyContentTagTaxonomyTerms,
        pharmacyRecertificationTaxonomyTerms: activity?.pharmacyRecertificationTaxonomyTerms,
        supportedRemsIds: remsIds,
        targetAudience: targetAudienceList,
        tempDatesAndLocation,
        isIndividualActivity: activity.isIndividualActivity,
        iaOrganizationName: activity.iaOrganizationName,
      };
    }
  },
  validateOnBlur: true,
  validateOnChange: false,
  validationSchema: ({ schemas, editIndex }: IEditActivityProps) =>
    !!schemas && isNumber(editIndex) && schemas[editIndex],
})(EditActivityFormik);

export default EditActivityForm;
